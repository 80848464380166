import React from 'react';

import { SETTINGS } from '../setup/config';
import AnimatedPage from './AnimatedPage';
import ContactIntro from '../components/ContactIntro';
import ContentBlock from '../components/ContentBlock';
import ContentBlocks from '../components/ContentBlocks';
import About from '../components/About';
import Separator from '../components/Separator';
import Button from '../components/Button';
import IconLinkedin from '../components/assets/IconLinkedin';
import { ButtonTypes } from '../setup/enum-buttontypes';

function Contact() {
	return (
		<AnimatedPage title={'Contact'}>
			<ContactIntro />
			<Separator type={2} background={'body-background'} />
			<ContentBlocks background={'transparent'} extraClass="contentblocks--inline">
				<div className="columnlayout__column">
					<ContentBlock
						className="contentblocks__contentblock1 contentblock--white"
						button={<Button newTab={true} link={SETTINGS.linkedin} text={'Volg mij'} icon={<IconLinkedin />} type={ButtonTypes.ButtonIcon} />}
						content={
							<>
								<h2>Wat kan ik voor u doen?</h2>
								<p>
									Ik ben dierenarts voor gezelschapsdieren en pas daarbij osteopathie, chiropractie en orthomoleculaire therapie voor hond en kat toe. Ik ben beschikbaar voor het doen van consulten/spreekuur en/of de manuele therapieën binnen de (dierenarts)praktijk.{' '}
									<strong>Stuur mij een e-mail voor de mogelijkheden.</strong>
								</p>
								<p>
									<br />
									Honden- en kattenbaasjes kunnen mij benaderen voor het advies over voeding en (orthomoleculaire) suppletie. Dit kan ook zeker op afstand. Neem gerust contact op per e-mail en ik beantwoord uw vraag of stuur het intakeformulier aan u toe.
								</p>
							</>
						}
					/>
				</div>
				<div className="columnlayout__column">
					<ContentBlock
						className="contentblocks__contentblock2"
						content={
							<>
								<h2>Afspraak maken</h2>
								<p>Mijn werkgebied voor consulten op locatie is Noord-Holland (tot ca. 45 minuten vanaf Heemskerk). Mijn interesses liggen in de interne diergeneeskunde, voeding en orthomoleculaire therapie en de manuele therapieën bij honden en katten.</p>
							</>
						}
					/>
				</div>
			</ContentBlocks>
			<About />
		</AnimatedPage>
	);
}

export default Contact;
