import AnimatedPage from './AnimatedPage';

import Intro from '../components/Intro';
import Separator from '../components/Separator';
import ContentBlocks from '../components/ContentBlocks';
import AnimatedImage from '../components/assets/AnimatedImage';
import UspSummary from '../components/UspSummary';
import PageBorder from '../components/PageBorder';
import About from '../components/About';
import ContentBlock from '../components/ContentBlock';
import Button from '../components/Button';
import { ButtonTypes } from '../setup/enum-buttontypes';

function Home() {
	return (
		<AnimatedPage title={'Welkom'}>
			<PageBorder>
				<Intro title="Dierenarts voor gezonde én gelukkige dieren" text="Diergeneeskundige zorg, osteopathie, chiropractie en orthomoleculaire therapie voor hond en kat." hideButtonMobile={true} />
				<Separator />
				<ContentBlocks>
					<div className="columnlayout__column">
						<ContentBlock
							className="contentblocks__contentblock1 contentblock--white contentblock--mt"
							button={<Button link={'/overmij'} text={'Meer over mij'} type={ButtonTypes.ButtonIcon} />}
							content={
								<>
									<p>
										Ik ben Jolanda, <strong>dierenarts voor gezelschapsdieren</strong> in Noord-Holland. Al van jongs af aan wist ik dat ik 'iets met dieren' wilde gaan doen. Al gauw werd dat 'ik wil dierenarts worden'.{' '}
									</p>
								</>
							}
						/>
					</div>
					<div className="columnlayout__column">
						<ContentBlock
							className="contentblocks__contentblock3 contentblock--white"
							image={<AnimatedImage />}
							button={<Button link={'/advies'} text={'Advies'} type={ButtonTypes.ButtonIcon} />}
							content={
								<>
									<p>
										Naast dierenarts, osteopaat en chiropractor, ben ik ook <strong>orthomoleculair therapeut</strong> voor honden en katten. Orthomoleculaire therapie is een behandelwijze die met passende voeding en suppletie de gezondheid ondersteunt. Voor elke hond of kat bied ik een orthomoleculair voedingsadvies op maat aan. 
									</p>
								</>
							}
						/>
					</div>
				</ContentBlocks>
				<UspSummary />
			</PageBorder>
			<About />
		</AnimatedPage>
	);
}

export default Home;
