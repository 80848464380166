import React from 'react';

function IconCalendar() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 105" xmlSpace="preserve">
			<g>
				<path
					className="svg-fill svg-fill--white"
					d="M71.7,47.2c1.3,1.3,1.3,3.4,0,4.7c0,0,0,0,0,0L51.9,71.7c-1.3,1.3-3.4,1.3-4.7,0c0,0,0,0,0,0l-9.9-9.9
		c-1.3-1.3-1.3-3.4,0-4.7c1.3-1.3,3.4-1.3,4.7,0l7.6,7.6L67,47.2C68.3,45.9,70.4,45.9,71.7,47.2C71.7,47.2,71.7,47.2,71.7,47.2z"
				/>
				<path
					className="svg-fill svg-fill--white"
					d="M23.1,0c1.8,0,3.3,1.5,3.3,3.3v3.3h52.8V3.3c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3v3.3h6.6
		c7.3,0,13.2,5.9,13.2,13.2v72.6c0,7.3-5.9,13.2-13.2,13.2H13.2C5.9,105.7,0,99.7,0,92.5V19.8C0,12.5,5.9,6.6,13.2,6.6h6.6V3.3
		C19.8,1.5,21.3,0,23.1,0z M6.6,26.4v66c0,3.6,3,6.6,6.6,6.6h79.2c3.6,0,6.6-3,6.6-6.6v-66H6.6z"
				/>
			</g>
		</svg>
	);
}

export default IconCalendar;
