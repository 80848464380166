import AnimatedPage from './AnimatedPage';
import ContentSimple from '../components/ContentSimple';
import IconPDF from '../components/assets/IconPDF';

import Button from '../components/Button';
import { ButtonTypes } from '../setup/enum-buttontypes';

function Voorwaarden() {
	return (
		<AnimatedPage title={'Over mij'}>
			<ContentSimple
				className="contentsimple--light"
				button={<Button newTab icon={<IconPDF />} className="button--main" link={`${process.env.PUBLIC_URL}/algemenevoorwaardenjolandadierenarts.pdf`} text={'Download'} type={ButtonTypes.ButtonIcon} />}
				content={
					<>
						<h2>Algemene voorwaarden</h2>
						<p><strong>Algemene Voorwaarden ZZP Dierenarts</strong></p>
						<p>U kunt de Algemene voorwaarden als PDF downloaden naar uw computer.</p>
					</>
				}
			/>
		</AnimatedPage>
	);
}

export default Voorwaarden;
