import React, { ReactElement, useRef } from 'react';

import '../styles/_components/PageBorder.scss';
// import { useWindowScroll } from '@uidotdev/usehooks';
import swirlAnimation from '../animations/jd-swirl.json';
import Lottie from 'lottie-react';
import { useInView } from 'react-intersection-observer';

type PageBorderProps = {
	children: ReactElement[] | ReactElement;
};

function PageBorder({ children }: PageBorderProps) {
	// let [{ y }] = useWindowScroll();
	// let padding = 0;
	// if (!y) {
	// 	padding = 0;
	// } else {
	// 	padding = -150 + y / 10 > 0 ? -150 + y / 10 : 0;
	// }

	//  style={{ paddingLeft: padding + 'px', paddingRight: padding + 'px' }}

	const swirlRef = useRef<any>(null);
	const swirlRef2 = useRef<any>(null);
	const { ref, inView } = useInView({
		threshold: 0.4,
	});

	if (inView && swirlRef.current && swirlRef2.current) {
		swirlRef.current.play();
		swirlRef2.current.play();
	}

	return (
		<section className="pageborderwrapper">
			<div className="pageborder">
				<div className="pageborder__end" ref={ref}>
					<Lottie className="animation-lottie" lottieRef={swirlRef} animationData={swirlAnimation} autoplay={false} loop={false} />
					<Lottie className="animation-lottie animation-lottie--flipped" lottieRef={swirlRef2} animationData={swirlAnimation} autoplay={false} loop={false} />
					{/*<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 494 115.9">*/}
					{/*	<g>*/}
					{/*		<g>*/}
					{/*			<path*/}
					{/*				className="svg-fill svg-fill--white"*/}
					{/*				d="M468.6,51.1c-1.8-2.4-3.2-6.3-1.2-9.1c2.5-3.3,7.6-2.9,11-1.3c3.4,1.6,5.5,5.5,5.5,9.2*/}
					{/*			c-0.4,8.7-9,14.2-16.2,17.4c-2.9,1.7-10.7,2.3-15.6,2.3C442,69.9,432,68.2,422,67.7c-11.1-0.4-24.9,1.2-30.7,11.8*/}
					{/*			c-2.1,3.6-2.1,8.3-0.1,12c2,3.9,6.3,4.8,10.5,4.3c11.4-1.4,22-11.7,21.5-23.5c-0.3-7.1-5.7-13.2-11.8-16.7*/}
					{/*			c-6.3-3.8-13.9-5.1-21.2-3.8c-14.8,2.7-27,13.1-36.1,24.6c-3.6,5.5-8.8,11.2-14.1,15.7c-16.7,14.4-39.4,19.6-60.6,12.9*/}
					{/*			c-8.6-2.7-16.9-7.1-23.6-13.2C244.9,82,235.9,69,235.2,53.9c-0.8-17,9.5-33.5,24.6-41.1c10-4.9,21.6-6.8,32.5-4.3*/}
					{/*			c5.6,1.3,11.2,4.9,14.1,10.1c4,7.6,4.7,17.1,1.2,25c-3.7,7.7-12.4,13.8-21.1,12.1c-8-1.1-15.8-7.3-12.2-16.1*/}
					{/*			c1.6-4.4,8.7-11.6,13.4-7.7c1.6,1.5,2.1,3.3,2.7,5.1c0,0,0,0.1,0,0.1c0,0,0-0.2,0-0.2c0.1,1.1-0.2,2.1-0.8,3*/}
					{/*			c-1.7,2.9-6.1,2.5-7.4-0.5c0,0,0.5-0.3,0.5-0.3c2,3.4,6.7,1.8,6.3-2.1c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0,0,0,0.3,0,0.3*/}
					{/*			c-0.6-1.6-1.3-3.1-2.4-4.1c-0.8-0.7-1.9-0.7-2.9-0.3c-5.7,2.1-10.4,11-5.3,15.8c4.4,3.9,12.2,4.8,17.3,1.7*/}
					{/*			c2.6-1.5,4.9-3.9,6.6-6.6c4-6.6,3.1-15.8-0.6-22.4c-6.2-9.8-20.1-8.9-30.1-6.5c-16.2,3.8-29,18.7-30.1,35.2*/}
					{/*			c-0.6,9.4,2.6,18.4,8,26.1c3.8,5.4,8.5,10.4,13.6,14.5c10.5,8.1,24,12.3,37.3,12.4c20.5-0.1,39.8-11.8,51.6-28.2*/}
					{/*			c9.8-11.6,22.4-22,37.6-24.9c14.6-3.1,34.6,6,36.5,22.1c1,11.1-6.4,21.5-16.2,26c-5.6,2.7-12.7,4.2-18.6,1*/}
					{/*			c-4.7-2.7-7.1-8.2-7.2-13.4c-0.1-8.8,6.7-16.3,14.4-19.6c7.4-3.1,15.5-3.7,23.4-3.3c10,0.7,20,2.5,30,2.6c4.5,0,9,0.1,13.4-1.2*/}
					{/*			c6.7-2.5,17.7-8.4,15.3-17.1c-0.6-1.9-1.7-3.5-3.4-4.5c-3-1.9-9-2.3-9.8,2.2c-0.4,2,0.3,4.2,1.5,6*/}
					{/*			C469.1,50.7,468.6,51.1,468.6,51.1L468.6,51.1z"*/}
					{/*			/>*/}
					{/*		</g>*/}
					{/*		<g>*/}
					{/*			<path*/}
					{/*				className="svg-fill svg-fill--white"*/}
					{/*				d="M24.9,50.7c4.5-7.4-1.4-12-8.3-8.2c-5.7,3.6-4.4,10.6,0.3,14.9c3.1,3,7.1,4.9,11.1,6.5c1.8,0.7,4.3,1,6.5,1.2*/}
					{/*		c12.5,0.9,24.9-1.5,37.4-2.4c5.1-0.3,10.6-0.1,15.7,1c10.9,1.9,22.1,10,22.2,22c-0.2,10.2-7.9,16.9-17.9,15*/}
					{/*		c-13.4-2.2-25.1-14.4-24-28.5C68.9,61.1,79,53.6,88.8,50.7c10.1-3,20.8-0.3,29.8,4.6c9,4.9,16.6,11.9,23.2,19.7*/}
					{/*		c3.9,5.6,8.9,10.5,14.2,14.8c10.6,8.4,23.8,13.3,37.3,13.5c16.6-0.1,33.4-6.7,44.6-19.2c7.8-8.3,14.1-18.8,14.4-30.3*/}
					{/*		c0.7-17.9-12.8-34.7-30.1-38.8c-9.4-2.2-21.9-3.2-28.8,4.7c-3.4,4.2-4.6,10-4.4,15.4c0.2,6.4,3.8,12.2,9.1,15.4*/}
					{/*		c5.1,3.1,12.9,2.2,17.3-1.7c4.9-4.5,0.8-12.6-4.2-15.3c-3.5-2-5.2,0.8-6.3,3.9c0,0,0-0.3,0-0.3c0,0.9-0.1,1.5,0.4,2.4*/}
					{/*		c1.2,2.4,4.7,2.1,5.9-0.2c0,0,0.5,0.3,0.5,0.3c-0.9,1.8-3,3-5,2.4c-2.1-0.6-3.4-2.7-3.2-4.8c0,0,0,0.2,0,0.2c0,0,0-0.1,0-0.1*/}
					{/*		c0.5-1.8,1.1-3.6,2.7-5.1c3.3-2.9,8,0.4,10.4,3c6.2,6.7,5,15.6-3.7,19.3c-10.8,4.9-21.5-0.4-26.6-10.5c-2.8-6.1-2.8-13.2-1-19.6*/}
					{/*		c5.7-20.8,33.3-19.1,48.8-11.2c15,7.6,25.3,24.1,24.6,41.1c-0.6,13.1-7.6,24.9-16.5,34.1c-7.4,7.9-17.1,13.9-27.5,17*/}
					{/*		c-29,9.2-58.3-4-74.8-28.6c-12.3-16.2-36.5-33.2-57.2-20.7c-6.1,3.5-11.5,9.6-11.8,16.7c-0.4,9.4,6.3,18.2,14.9,21.7*/}
					{/*		c5.5,2.4,13.8,3.7,17.1-2.5c2-3.6,2-8.3-0.1-12C96.9,68.9,83.2,67.3,72,67.7C62,68.2,52,69.9,41.8,69.6c-4.9-0.2-10-0.3-14.7-1.9*/}
					{/*		c-0.3-0.1-0.6-0.2-0.9-0.4c-0.1-0.1-0.2-0.1-0.4-0.2l0.4,0.2c-4.3-1.8-8.3-4.2-11.6-7.6c-4.5-4.4-6.5-11.4-2-16.7*/}
					{/*		c3.5-4.5,13.9-5.5,14.9,1.6c0.2,2.4-0.8,4.7-2.1,6.5C25.4,51.1,24.9,50.7,24.9,50.7L24.9,50.7z"*/}
					{/*			/>*/}
					{/*		</g>*/}
					{/*	</g>*/}
					{/*</svg>*/}
				</div>
			</div>
			{children}
		</section>
	);
}

export default PageBorder;
