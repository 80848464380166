import React from 'react';

import { useInView } from 'react-intersection-observer';
import { ButtonTypes } from '../setup/enum-buttontypes';
import { Email } from 'react-obfuscate-email';

import '../styles/_components/ContactIntro.scss';
import Button from './Button';
import { SETTINGS } from '../setup/config';
import IconMail from './assets/IconMail';

function ContactIntro() {
	const { ref, inView } = useInView({
		threshold: 0,
	});

	return (
		<section className={`contactintro${inView ? ' is--inview' : ''}`} ref={ref}>
			<div className="container contactintro__container">
				<div className="contactintro__text">
					<h1>Contact opnemen</h1>
					<p>Als je op zoek bent naar een holistisch werkend dierenarts of advies wilt over voeding, orthomoleculaire suppletie, een passende behandeling, of andere vormen van ondersteuning voor jouw huisdier, aarzel dan niet om vrijblijvend contact met mij op te nemen via e-mail. </p><p>
					<br />Ook praktijkeigenaren en andere dierprofessionals zijn van harte welkom om te informeren of we iets voor elkaar kunnen betekenen. Ik help graag!</p>
				</div>
				<div className="contactintro__button">
					<Button className={'button--main'} link={'email'} text={'Stuur e-mail'} icon={<IconMail />} type={ButtonTypes.Email} />
					<p>
						<small>
							<Email email={SETTINGS.email}>{SETTINGS.email}</Email>
						</small>
					</p>
				</div>
			</div>
		</section>
	);
}

export default ContactIntro;
