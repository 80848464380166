import { useInView } from 'react-intersection-observer';
import '../styles/_components/About.scss';
import { SETTINGS } from '../setup/config';
import Button from './Button';
import IconMail from './assets/IconMail';
import IconLinkedin from './assets/IconLinkedin';
import { ButtonTypes } from '../setup/enum-buttontypes';
import RoundLogo from './assets/RoundLogo';

function About() {
	const { ref, inView } = useInView({
		threshold: 0,
	});

	return (
		<section className={`about${inView ? ' is--inview' : ''}`} ref={ref}>
			<div className="container about__container">
				<div className="about__icons">
					<a href={'mailto:' + SETTINGS.email} target="_blank" rel="noreferrer" className="icon about__icons__icon about__icons__icon1">
						<div className="icon__svg">
							<IconMail />
						</div>
						<div className="icon__pointer">
							<span>
								{' '}
								<span> </span>{' '}
							</span>
						</div>
					</a>
					<a href={SETTINGS.linkedin} target="_blank" rel="noreferrer" className="icon about__icons__icon about__icons__icon2">
						<div className="icon__svg">
							<IconLinkedin />
						</div>
						<div className="icon__pointer">
							<span>
								{' '}
								<span> </span>{' '}
							</span>
						</div>
					</a>
					{/* <a href={SETTINGS.cv} target="_blank" rel="noreferrer" className="icon icon--wide about__icons__icon about__icons__icon3">
						<div className="icon__svg">
							<IconCV />
						</div>
						<div className="icon__pointer">
							<span>
								{' '}
								<span> </span>{' '}
							</span>
						</div>
					</a> */}
				</div>
				<div className="about__gallery">
					<div className="about__photo">
						<div>
							<RoundLogo color={'secondary--light'} />
						</div>
					</div>
				</div>
				<div className="about__text">
					<h2>Jolanda Rietveld</h2>
					<p>Dierenarts voor gezonde én gelukkige dieren</p>
				</div>
				<div className="about__button">
					<Button link={'mailto:' + SETTINGS.email} text={'Stuur e-mail'} icon={<IconMail />} type={ButtonTypes.Email} />
				</div>
			</div>
		</section>
	);
}

export default About;
