import React from 'react';

import '../styles/_components/MenuButton.scss';

type MenuButtonProps = {
	toggleMenu: any;
	menuButtonActive: boolean;
};

function MenuButton({ toggleMenu, menuButtonActive }: MenuButtonProps) {
	return (
		<div className={`menubutton ${menuButtonActive ? 'is--active' : ''}`} onClick={toggleMenu}>
			<div className="menubutton__background"> </div>
			<div className="menubutton__close">
				<div className="menubutton__closeline">
					<span> </span>
				</div>
				<div className="menubutton__closeline">
					<span> </span>
				</div>
			</div>
			<div className="menubutton__content">
				<div className="menubutton__lines">
					<div className="menubutton__line">
						<div className="menubutton__line__element">
							<span> </span>
						</div>
					</div>
					<div className="menubutton__line">
						<div className="menubutton__line__element">
							<span> </span>
						</div>
					</div>
					<div className="menubutton__line">
						<div className="menubutton__line__element">
							<span> </span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MenuButton;
