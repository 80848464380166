import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../styles/_components/ContentBlock.scss';

type ContentBlockProps = {
	className?: string;
	content?: React.ReactElement;
	button?: React.ReactElement;
	image?: React.ReactElement;
	link?: string;
};

function ContentBlock({ className = '', button, image, content, link }: ContentBlockProps) {
	const navigate = useNavigate();

	const handleBlockClick = (event: any) => {
		event.preventDefault();
		if (event.target.classList.contains('contentblock')) {
			const link = event.target.dataset.link;
			navigate(link);
		}
	};

	return (
		<div className={`contentblock ${button ? 'has--button' : ''} ${image ? 'has--image' : ''} ${className}`} onClick={link ? handleBlockClick : () => false} data-link={link ? link : null}>
			<div className="contentblock__text">{content}</div>
			{button ? <div className="contentblock__button">{button}</div> : <></>}
			{image ? <div className="contentblock__image">{image}</div> : <></>}
		</div>
	);
}

export default ContentBlock;
