import React, { useState } from 'react';
import IconArrowDown from './assets/IconArrowDown';
import { ButtonTypes } from '../setup/enum-buttontypes';
import { Link } from 'react-scroll';
import IconArrowRight from './assets/IconArrowRight';
import { NavLink } from 'react-router-dom';
import { SETTINGS } from '../setup/config';

type ButtonProps = {
	type?: ButtonTypes;
	className?: string;
	text?: string;
	link: string;
	icon?: React.ReactElement | null;
	newTab?: boolean | false;
};

function Button({ type, className = '', text = '', link = '', icon = null, newTab = false }: ButtonProps) {
	const [emailButtonClicked, setEmailButtonClicked] = useState(false);

	const handleEmailClick = (event: any) => {
		if (!emailButtonClicked) {
			event.preventDefault();
			setEmailButtonClicked(true);
		}
	};

	switch (type) {
		case ButtonTypes.Down:
			return (
				<Link className={`button ${className}`} to={link} spy={true} smooth={true} offset={-100} duration={500}>
					<div className="button__contents">
						<div className="button__icon">
							<IconArrowDown />
						</div>
						<div className="button__background"> </div>
						<div className="button__inner">
							<span>{text}</span>
						</div>
					</div>
				</Link>
			);
		case ButtonTypes.Icon:
			return (
				<NavLink className={`button button--icon ${className}`} to={link}>
					<div className="button__contents">
						<div className="button__background"> </div>
						<div className="button__inner">
							<span>
								<IconArrowRight />
							</span>
						</div>
					</div>
				</NavLink>
			);
		case ButtonTypes.ButtonIcon:
			if (newTab) {
				return (
					<a href={link} target="_blank" rel="noreferrer" className={`button button--buttonicon ${className}`}>
						<div className="button__contents">
							<div className="button__background"> </div>
							<div className="button__inner">
								{icon}
								<span>{text}</span>
							</div>
						</div>
					</a>
				);
			} else {
				return (
					<NavLink className={`button button--buttonicon ${className}`} to={link}>
						<div className="button__contents">
							<div className="button__background"> </div>
							<div className="button__inner">
								{icon}
								<span>{text}</span>
							</div>
						</div>
					</NavLink>
				);
			}
		case ButtonTypes.Email:
			return (
				<NavLink className={`button button--buttonicon ${className}`} to={emailButtonClicked ? 'mailto:' + SETTINGS.email : '/'} target={'_blank'} onClick={handleEmailClick}>
					<div className="button__contents">
						<div className="button__background"> </div>
						<div className="button__inner">
							{icon}
							<span>{text}</span>
						</div>
					</div>
				</NavLink>
			);
		default:
			return <></>;
	}
}

export default Button;
