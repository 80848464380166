import React from 'react';

import '../styles/_components/ContentSimple.scss';

type ContentSimpleProps = {
	className?: string;
	content?: React.ReactElement;
	button?: React.ReactElement;
	centered?: boolean
};

function ContentBlock({ className = '', content, button, centered = true }: ContentSimpleProps) {
	return (
		<div className={`contentsimple ${className}`}>
			<div className={`contentsimple__text container${centered ? ' container--centered' : ' container--wide'}`}>
				{content}
				{button ? <div className="contentsimple__button">{button}</div> : null}
			</div>
		</div>
	);
}

export default ContentBlock;
