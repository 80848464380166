import React from 'react';

import '../styles/_components/ImageLogo.scss';

type ImageLogoProps = {
	className: string;
};

function ImageLogo({ className }: ImageLogoProps) {
	return (
		<div className={`imagelogo ${className}`}>
			<div className="imagelogo__content">
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 800 800" xmlSpace="preserve">
					<g>
						<path
							className="svg-fill svg-fill--main"
							d="M431.3,354.3c-40.6,67.6-99.4-8.1-134.5,33.9c-35.6,51.6-55.5,112-92.3,163c-9.6,16.6-25.5,28-36.9,41.8
		C125.3,541,99.8,474.7,99.8,402.4c0-29.2,4.1-57.4,11.9-84.1c71.7-35.5,131.1-95,167.7-166.4c22.7-55.8,25.5,18.1,27.5,38.9
		c5.3,34.6,64,38.2,63.2,77.7c3.8,22,49,37.4,66.2,53.1C452.9,330.8,438.5,343.9,431.3,354.3z"
						/>
						<path
							className="svg-fill svg-fill--main-dark"
							d="M758.1,412.8c-0.1,28.7-3.9,75.8-25.7,128.7c-1.4,3.4-3.5,8.3-6.3,14.3c-10.7,22.8-32.5,62.1-71.1,100.6
		C588.8,722.8,501,760,407.3,761.5c-2,0-3.9,0.1-5.9,0.1c-93.6,0-181.7-35.6-248.9-100.4c-1.6-1.5-3.2-3.1-4.8-4.7
		C80,588.7,42.7,498.6,42.7,402.8c0-20.4,1.7-40.5,5-60.2c12.2-72.9,46.7-140.1,100.1-193.5c33.4-33.4,72.3-59.4,114.6-77.2
		c38.3-16.1,72.7-22.6,97.2-25.5c3.6,3,7.7,6.6,11.7,11c4.9,5.4,8.7,10.6,11.7,15.2C232.2,83,110.2,192.8,79,332.5
		c-5.1,22.7-7.8,46.2-7.8,70.3c0,85.1,33.5,158.7,84,214.6c63.4,70.2,153.4,112.6,237.2,115.5c3,0.1,6,0.2,9,0.2
		c126.3,0,236.2-71.2,291.7-175.6c16.5-31.1,28.2-65,34.1-101c4.7-2.5,13.5-8,20.8-18.5C755.1,427.9,757.3,418.1,758.1,412.8z"
						/>
						<path
							className="svg-fill svg-fill--main"
							d="M401.4,704c-7,0-13.9-0.2-20.7-0.7c-0.2-0.5-0.4-1.1-0.6-1.7c-0.7-1.9-1.3-3.7-2-5.7
		c-12.5-46.3-6.5-100.6-39.6-137.8c-16.3-5.7-26.1-17.7-31.4-33.7c-6.7-18.7,2.9-46.4,27.3-51.9c11.5-15.4,28.8-25.6,48.9-26.2
		c10.6-0.1-6.1-33.2-1.5-43.4c36.5,56.4,31,67.7,82.9,12.3c39.4-27.1,36.1,40.2,26.4,61.8c-1.4,9.4-2.4,26.1,5.1,44.2
		c9.9,24,31.6,43.2,57,48.7c8.5,1.9,15.8,1.8,20.7,1.5c27-4.8,54.3-8.5,81.6-11.1c0.9-0.1,1.9-0.2,2.8-0.3
		C605.5,646.5,510.2,704,401.4,704z"
						/>
						<path
							className="svg-fill svg-fill--main-dark"
							d="M683.3,411.9c0-22.6-0.8-45.4-2.8-68L605,295l72.8,24.3c-4.3-32.1-11.3-63.9-22.4-94.4
		c-8.4-22.7-19.4-44.8-34.5-64.4c18.2,16.8,33.2,37.3,45.8,59.1c7.6,13.4,14.4,27.3,20.5,41.4l9.7-32.4l1.2,59.9
		c14.1,38.8,23.6,79.3,30.3,119.9c11.6-16.8,18-39.8,17.5-66.9c0.2-59.2-33.5-138-87.1-191.2C606.9,93.4,535.2,62.1,482.5,49.6
		c-53.7-13.3-88.4-8-89.2-9.3c-1.4,0.7,30,21.8,60.1,58.9c31.1,36.5,60.9,88.9,78.7,137.8c39.4,97.8,31.1,189.3,133.3,200.5
		c5.9,0.5,11.6,0.4,17-0.3C683,429.5,683.2,419.4,683.3,411.9z"
						/>
					</g>
				</svg>
			</div>
		</div>
	);
}

export default ImageLogo;
