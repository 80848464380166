import React, { ReactElement } from 'react';

import '../styles/_components/ContentBlocks.scss';

import { Element } from 'react-scroll';
import { useInView } from 'react-intersection-observer';

type ContentBlocksProps = {
	children: ReactElement[] | ReactElement;
	background?: string;
	extraClass?: string;
};

function ContentBlocks({ children, background, extraClass }: ContentBlocksProps) {
	const { ref, inView } = useInView({
		threshold: 0,
	});

	return (
		<div className={`contentblocksholder ${inView ? ' is--inview' : ''}`} ref={ref}>
			<Element className={`contentblocks contentblocks--${background} ${extraClass}`} name="sectionContentBlocks">
				<div className="container container--wide contentblocks__container columnlayout">{children}</div>
			</Element>
		</div>
	);
}

export default ContentBlocks;
