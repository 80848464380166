import React from 'react';

import '../styles/_components/UspSummary.scss';
import { ButtonTypes } from '../setup/enum-buttontypes';
import Button from './Button';
import IconCalendar from './assets/IconCalendar';
import { useWindowScroll } from '@uidotdev/usehooks';
import { useInView } from 'react-intersection-observer';
import IconPlant from './assets/IconPlant';
import IconDoc from './assets/IconDoc';
import IconHeart from './assets/IconHeart';

function UspSummary() {
	let [{ y }] = useWindowScroll();
	if (!y) {
		y = 0;
	}

	const { ref, inView } = useInView({
		threshold: 0.4,
	});

	return (
		<section className={`uspsummary${inView ? ' is--inview' : ''}`} ref={ref}>
			<div className="uspsummary__topbar">
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 5972.9 1050.4">
					<path
						className="svg-fill svg-fill--main"
						d="M-73.5,1050.4h4159.6h1017h943.4V303.3l-62.9-0.2c19.4-23.4-14-55-36.3-34.2c-22.4,20.5-55,51.2-77.5,71c-60.6,54.8-125.1,109.4-189.6,160.9l-440.6,287.2c-98.8,49.2-201.9,89.4-309.1,115.3c-50,11.8-102.1,21-153.3,25.4
					c-910,79.6-1106.2-1197.5-2244.1-793.7c-200.9,75.2-347.5,242.8-544.2,322.3C1561.4,595.3,1206.4,227.1,793,192.7
					c-260.4-30.3-529.1,43.3-742.3,194.4c-33.7,23.6-67.2,50.8-97.7,78.4c-12.1,12.4-31.8,23.3-27.2,43.6L-73.5,1050.4z"
					/>
				</svg>
			</div>
			<div className="uspsummary__contents">
				<div className="container uspsummary__contents__container">
					<div className="iconcollection uspsummary__contents__iconcollection">
						<div className="iconcollection__icon iconcollection__icon--offsettop">
							<div className="iconcollection__icon__svg">
								<IconHeart />
							</div>
							<div className="iconcollection__icon__inner" style={{ transform: 'rotate(' + y / 4 + 'deg)' }}>
								<span> </span>
							</div>
						</div>
						<div className="iconcollection__icon iconcollection__icon--small">
							<div className="iconcollection__icon__svg">
								<IconPlant />
							</div>
							<div className="iconcollection__icon__inner" style={{ transform: 'rotate(' + y / 5 + 'deg)' }}>
								<span> </span>
							</div>
						</div>
						<div className="iconcollection__icon iconcollection__icon--big">
							<div className="iconcollection__icon__svg">
								<IconDoc />
							</div>
							<div className="iconcollection__icon__inner" style={{ transform: 'rotate(' + y / 6 + 'deg)' }}>
								<span> </span>
							</div>
						</div>
					</div>
					<p className="text-center">Mijn interesses liggen in de interne diergeneeskunde, voeding en orthomoleculaire therapie en de manuele therapieën bij honden en katten. Mijn werkgebied voor consulten op locatie is Noord-Holland (tot ca. 45 minuten vanaf Heemskerk).</p>
					<div className="uspsummary__contents__button">
						<Button link={'/contact'} text={'Neem contact op'} icon={<IconCalendar />} type={ButtonTypes.ButtonIcon} />
					</div>
				</div>
			</div>
		</section>
	);
}

export default UspSummary;
