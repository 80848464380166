function SeparatorSmall() {
	return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6274.7 1406.4" preserveAspectRatio="none">
    <g>
        <path
            className="svg-fill svg-fill--main"
            d="M6138.9,396.2c-16.8,20.6-42.3,51.9-58.8,71.7c-11.7,13.5-40,47-51.8,60.4c-16.3,18.2-36.8,41.3-53.1,59.5
c-12.4,13-42.1,45.4-54.6,58.4c-15.1,15.7-36.1,36.8-51.3,52.5c-33.6,33-76.7,74.7-111.8,106c-15.3,13.4-35.3,31.5-50.6,44.4
c-11.5,9.7-29.8,25-41.3,34.7c-27.7,22.2-67.1,53.3-96.4,74.8c-15.5,11.4-36.9,26.3-52.8,37.6c-57.8,39.3-117.5,76.3-179.4,109.1
c-12.7,7-36.2,18.5-49.7,25.3c-16.5,8.1-34.1,15.9-50.8,23.6c-24.4,10.5-53.5,22.6-78.4,31.8c-6.8,2.8-19.5,7-26.7,9.6
c0,0-13.4,4.7-13.4,4.7s-6.7,2.3-6.7,2.3c-2.2,0.8-4.5,1.6-6.8,2.2c-12.4,4-29.6,9.1-41,12.6c-13.4,3.5-28.4,7.9-41.6,11.2
c-190.9,48.3-392.1,47.9-582-5.7c-75.8-21.2-150.3-51-220.4-86.1c-197.1-97.8-365.8-241.9-540.3-367.6
c-177-127.3-357.4-225.7-578.7-205.2c-51.8,4.3-103.6,15-152,33.8c-37.5,14.3-73,33.9-106,56.7c-31.3,21.5-60.9,46-89.4,71
c-41.6,36.7-86.6,79.3-128.5,115.8c-47.8,41.7-99.7,82.6-154,117.2c-43.6,27.7-89.7,52.2-137.4,72.1
c-101.8,42.8-212.2,65.1-322.5,68.2c-149.2,4.7-300.3-23.1-438.7-78c-57.4-22.6-113.6-49.6-167.6-79.4
c-72.3-39.6-142.3-85.3-211.1-130.5c-71.6-47-145.8-96-221.9-135.2c-68.2-35.4-140.1-63.4-215-81.3c-40.6-9.7-82.3-16.8-123.8-21.2
c-29.5-3.2-59-5-88.7-6.1c0,0-3-0.1-3-0.1s-3,0-3,0c-8-0.1-16-0.1-24-0.3c-8.1-0.3-16.1,0-24.2,0.1c0,0-12.2,0.2-12.2,0.2
s-6.1,0.1-6.1,0.1c-2,0-4.1,0.2-6.1,0.2c0,0-24.5,1-24.5,1c-4.1,0.1-8.2,0.5-12.3,0.7c0,0-12.3,0.8-12.3,0.8s-12.4,0.8-12.4,0.8
c-8.2,0.7-16.5,1.6-24.7,2.3c0,0-6.2,0.5-6.2,0.5s-3.1,0.3-3.1,0.3c-9.2,1.1-18.7,2.2-28,3.2c-4.2,0.4-8.3,1.1-12.5,1.6
c0,0-12.5,1.7-12.5,1.7s-6.3,0.9-6.3,0.9c-66.7,9.5-131.7,23.8-197.6,40.1c-13.9,3.6-28.3-4.9-31.6-18.9
c-2.2-9.2,0.8-18.4,7.1-24.6c6.7-6.4,13.3-12.8,20.1-19c30.5-27.6,63.9-54.8,97.7-78.4c7.5-5.2,15.8-11.3,23.1-16.1
c11.4-7.5,24.1-15.9,35.7-22.8c77.2-47.6,161.9-86,248.7-112.9c140-44,288.6-59.6,434.8-42.6c106.5,11.9,210.2,40.5,309.8,77.6
c56.4,20.9,113.3,45.2,168.4,69.3c182.7,80.1,332.3,150.7,536.7,147.6c62.7-0.8,122.5-9,180.9-29.8c14.9-5.4,29.9-11.6,44.3-18.3
c15-7.1,30.2-15.1,44.7-23.3c80.9-46.7,157.2-108.1,235.3-160c67.7-45.7,142.6-89,219.9-120.7c106.7-44.2,221-68.3,336-73.1
c202-9.1,394,32,571.9,128.2c233.3,123.8,412,320.9,611.2,485.4c95.7,78.3,193.4,145.8,306.6,192.3
c124.3,51.5,260.6,71.8,394.8,62.8c2-0.1,3.9-0.2,5.9-0.3c0,0,5.9-0.5,5.9-0.5s11.8-1,11.8-1s11.8-1,11.8-1s2.9-0.3,2.9-0.3
s2.9-0.4,2.9-0.4s5.9-0.7,5.9-0.7s23.6-2.9,23.6-2.9c2-0.2,3.9-0.6,5.9-0.9c0,0,5.9-0.9,5.9-0.9s11.8-1.9,11.8-1.9
s11.8-1.8,11.8-1.8c3.9-0.6,7.9-1.5,11.8-2.2c0,0,23.6-4.5,23.6-4.5c2-0.3,3.9-0.8,5.9-1.2c0,0,5.9-1.3,5.9-1.3s11.8-2.7,11.8-2.7
s11.8-2.7,11.8-2.7c2-0.5,3.9-0.8,5.9-1.4c0,0,5.9-1.5,5.9-1.5s23.5-6.1,23.5-6.1c7.9-1.9,15.6-4.4,23.5-6.7c0,0,23.4-6.9,23.4-6.9
c7.8-2.5,15.5-5.2,23.3-7.7c0,0,11.7-3.9,11.7-3.9c3.9-1.3,7.8-2.5,11.6-4c0,0,23.2-8.5,23.2-8.5s11.6-4.3,11.6-4.3
s2.9-1.1,2.9-1.1s2.9-1.2,2.9-1.2s5.8-2.3,5.8-2.3s23-9.3,23-9.3s11.5-4.6,11.5-4.6c3.8-1.6,7.6-3.3,11.4-5c0,0,22.9-10,22.9-10
s11.4-5,11.4-5c12.7-6,32.7-15.5,45.4-21.5c0,0,11.2-5.7,11.2-5.7s22.5-11.4,22.5-11.4s11.2-5.7,11.2-5.7s2.8-1.4,2.8-1.4
s2.8-1.5,2.8-1.5s5.5-3,5.5-3s22.2-12.1,22.2-12.1s11.1-6.1,11.1-6.1c3.7-2,7.4-4,11.1-6.2c0,0,44-25.5,44-25.5
c18.1-11.1,36.3-22.2,54.3-33.5c0,0,10.7-7,10.7-7s21.5-14,21.5-14s10.7-7,10.7-7s5.4-3.5,5.4-3.5s2.7-1.8,2.7-1.8s2.6-1.8,2.6-1.8
s21.2-14.5,21.2-14.5c45.9-31,96.8-69,140.8-102.5c0,0,5.1-4,5.1-4s20.3-16.1,20.3-16.1s20.3-16.1,20.3-16.1s10.2-8,10.2-8
c3.4-2.7,6.7-5.5,10-8.2c0,0,40.1-33.1,40.1-33.1c23.5-19.4,46.5-39.8,69.4-59.2c3.3-2.8,6.5-5.8,9.7-8.7c0,0,38.9-34.8,38.9-34.8
s9.8-8.7,9.8-8.7s4.9-4.4,4.9-4.4s4.8-4.4,4.8-4.4s19.2-17.7,19.2-17.7s38.5-35.5,38.5-35.5s0.4-0.3,0.4-0.3
c10.1-9.4,26-8.7,35.3,1.4C6146.5,372.7,6146.7,386.7,6138.9,396.2L6138.9,396.2z"
        />
    </g>
</svg>
}

export default SeparatorSmall;
