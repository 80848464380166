import React from 'react';

function IconDoc() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 105" xmlSpace="preserve">
			<g>
				<path
					className="svg-fill svg-fill--white"
					d="M65.2,6.4c0-1.7-1.4-3.1-3.1-3.1H43.6c-1.7,0-3.1,1.4-3.1,3.1s-1.4,3.1-3.1,3.1s-3.1,1.4-3.1,3.1v3.1
		c0,1.7,1.4,3.1,3.1,3.1h30.9c1.7,0,3.1-1.4,3.1-3.1v-3.1c0-1.7-1.4-3.1-3.1-3.1S65.2,8.2,65.2,6.4z"
				/>
				<path
					className="svg-fill svg-fill--white"
					d="M28.6,9.5H25c-5.1,0-9.3,4.2-9.3,9.3v74.2c0,5.1,4.2,9.3,9.3,9.3l0,0h55.7c5.1,0,9.3-4.2,9.3-9.3l0,0V18.8
		c0-5.1-4.2-9.3-9.3-9.3h-3.6c0.3,1,0.5,2,0.5,3.1v3.1c0,5.1-4.2,9.3-9.3,9.3H37.4c-5.1,0-9.3-4.2-9.3-9.3v-3.1
		C28.1,11.5,28.3,10.5,28.6,9.5z M65.1,36.5l8.6,28.7h0.8c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1h-3.1c-1.4,0-2.6-0.9-3-2.2
		l-5.8-19.4l-6.8,31.5c-0.4,1.7-2,2.7-3.7,2.4c-1.1-0.2-2-1.1-2.3-2.1l-7-23.5l-5.8,11.6c-0.5,1-1.6,1.7-2.8,1.7h-3.1
		c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1h1.2l8.4-16.9c0.8-1.5,2.6-2.1,4.2-1.4c0.8,0.4,1.3,1.1,1.6,1.9l5.8,19.4l6.8-31.5
		c0.4-1.7,2-2.7,3.7-2.4C63.9,34.6,64.8,35.4,65.1,36.5L65.1,36.5z"
				/>
			</g>
		</svg>
	);
}

export default IconDoc;
