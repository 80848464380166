import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';

function Page() {
	const location = useLocation();
	return (
		<main className="main">
			<Outlet />
			<Footer className={`${location.pathname === '/overmij' ? 'footer--simple' : ''}`} />
		</main>
	);
}

export default Page;
