import React from 'react';

import ImageLogo from '../components/ImageLogo';
import { useInView } from 'react-intersection-observer';
import { ButtonTypes } from '../setup/enum-buttontypes';

import '../styles/_components/Intro.scss';
import Button from './Button';

type IntroProps = {
	title: string;
	subtitle?: string | null;
	text?: string | null;
	logo?: boolean;
	buttonText?: string;
	hideButtonMobile?: boolean;
	titleSmall?: boolean;
	children?: React.ReactNode;
};

function Intro({ title, subtitle = null, text, logo = true, buttonText = 'Over mij', hideButtonMobile = false, titleSmall = false, children }: IntroProps) {
	const { ref, inView } = useInView({
		threshold: 0,
	});

	return (
		<section className={`intro${inView ? ' is--inview' : ''}`} ref={ref}>
			<div className="container intro__container">
				{logo ? <ImageLogo className="intro__imagelogo" /> : null}
				<div className="intro__text">
					{subtitle && <strong className="label">{subtitle}</strong>}
					<h2 className={`${titleSmall ? ' small' : ''}`} >{title}</h2>
					{text && <p>{text}</p>}
					{children}
				</div>
				{!hideButtonMobile ? <div className="intro__button">
					<Button link={'sectionContentBlocks'} text={buttonText} type={ButtonTypes.Down} />
				</div> : null}
			</div>
		</section>
	);
}

export default Intro;
