import React from 'react';

function IconLinkedin() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 105" xmlSpace="preserve">
			<g>
				<path
					className="svg-fill svg-fill--white"
					d="M2.1,9.4c0-4,3.3-7.3,7.5-7.3h86.6c4.1,0,7.5,3.3,7.5,7.3v87c0,4-3.3,7.3-7.5,7.3H9.5c-4.1,0-7.5-3.3-7.5-7.3V9.4z
	 M33.5,87.1V41.2H18.2v45.8H33.5z M25.8,35c5.3,0,8.6-3.5,8.6-7.9c-0.1-4.5-3.3-7.9-8.5-7.9s-8.6,3.4-8.6,7.9c0,4.4,3.3,7.9,8.4,7.9
	H25.8z M57,87.1V61.5c0-1.4,0.1-2.7,0.5-3.7c1.1-2.7,3.6-5.6,7.8-5.6c5.5,0,7.7,4.2,7.7,10.4v24.5h15.2V60.8
	c0-14.1-7.5-20.6-17.5-20.6c-8.1,0-11.7,4.4-13.7,7.6v0.2h-0.1c0-0.1,0.1-0.1,0.1-0.2v-6.5H41.8c0.2,4.3,0,45.8,0,45.8H57z"
				/>
			</g>
		</svg>
	);
}

export default IconLinkedin;
