import React from 'react';

function IconArrowDown() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 200 200" xmlSpace="preserve">
			<g>
				<line className="svg-stroke svg-stroke--light svg-stroke--fat" x1="100" y1="14.4" x2="100" y2="185.6" />
				<line className="svg-stroke svg-stroke--light svg-stroke--fat" x1="100" y1="185.6" x2="159.2" y2="125.1" />
				<line className="svg-stroke svg-stroke--light svg-stroke--fat" x1="100" y1="185.6" x2="40.8" y2="125.1" />
			</g>
		</svg>
	);
}

export default IconArrowDown;
