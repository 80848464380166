import { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Page from './pages/Page';
import Contact from './pages/Contact';
import Overmij from './pages/Overmij';
import Home from './pages/Home';
import Advies from './pages/Advies';
import Voorwaarden from './pages/Voorwaarden';

import SplashScreen from './components/SplashScreen';
import Logo from './components/Logo';
import MenuButton from './components/MenuButton';
import Menu from './components/Menu';
import Header from './components/Header';
import { useWindowScroll } from '@uidotdev/usehooks';

function App() {
	const location = useLocation();
	const body = document.querySelector('body') || document.body;
	document.body.classList.add('app--loaded');

	let [{ y }] = useWindowScroll();

	y && y > 50 ? body.classList.add('is--scrolled') : body.classList.remove('is--scrolled');

	const [menuButtonActive, setMenuButtonActive] = useState(false);
	const [introDone, setIntroDone] = useState(false);

	const toggleMenu = () => {
		setMenuButtonActive(!menuButtonActive);
		document.body.classList.toggle('menu--active');
	};

	const toggleIntro = () => {
		setIntroDone(true);
		document.body.classList.toggle('intro--completed');
	};

	return (
		<>
			<SplashScreen setIntroDone={toggleIntro} />
			{introDone ? (
				<>
					<Menu toggleMenu={toggleMenu} menuButtonActive={menuButtonActive} />
					<Header>
						<Logo />
						<MenuButton toggleMenu={toggleMenu} menuButtonActive={menuButtonActive} />
					</Header>

					<Routes key={location.pathname} location={location}>
						<Route path="/" element={<Page />}>
							<Route path="/" element={<Home />} />
							<Route path="contact" element={<Contact />} />
							<Route path="overmij" element={<Overmij />} />
							<Route path="voorwaarden" element={<Voorwaarden />} />
							<Route path="advies" element={<Advies />} />
						</Route>
					</Routes>
				</>
			) : (
				<></>
			)}
		</>
	);
}

export default App;
