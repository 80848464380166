import { SETTINGS } from '../setup/config';
import '../styles/_components/Footer.scss';
import rabbitAnimation from '../animations/jd-rabbit.json';
import Lottie from 'lottie-react';
import { NavLink } from 'react-router-dom';

type FooterProps = {
	className?: string;
};

function Footer({ className = '' }: FooterProps) {
	const year = new Date().getFullYear();
	return (
		<footer className={`footer ${className}`}>
			<div className="footer__contents container container--wide">
				<div className="footer__contents__image">
					<Lottie className="footer__contents__image__lottie" animationData={rabbitAnimation} loop={true} />
				</div>
				<h2 className="footer__title">Dierenarts, osteopaat, chiropractor en orthomoleculair therapeut voor hond en kat</h2>
				<p className="footer__texts">
					<span>&copy; {year}</span>
					<span className="text-mobilebreak">
						<strong>KvK</strong> {SETTINGS.kvk}
					</span>
					<span className="text-mobilebreak hidden">
						<strong>BTW</strong> {SETTINGS.btw}
					</span>
				</p>
				<p className="footer__texts">
					<NavLink to="/voorwaarden">Voorwaarden</NavLink>
					<a href="https://www.erwinrietveld.nl">Webdesign: Erwin Rietveld &#8599;</a>
				</p>
			</div>
		</footer>
	);
}

export default Footer;
