import React from 'react';

import '../styles/_components/Logo.scss';

function Logo() {
	return (
		<div className="logo">
			<h1>
				Dierenarts <strong>Jolanda</strong>
			</h1>
		</div>
	);
}

export default Logo;
