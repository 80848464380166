import React, { ReactElement } from 'react';

import '../styles/_components/Header.scss';

type HeaderProps = {
	children: ReactElement[];
};

function Header({ children }: HeaderProps) {
	return <div className="header">{children}</div>;
}

export default Header;
