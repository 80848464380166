import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/main.scss';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from './components/helpers/ScrollTop';
import { SETTINGS } from './setup/config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<BrowserRouter basename={SETTINGS.urlrelative}>
			<ScrollTop />
			<App />
		</BrowserRouter>
	</React.StrictMode>,
);
