export const SETTINGS = {
	// url: '',
	// urlrelative: '',
	url: 'https://www.dierenartsjolanda.nl/',
	urlrelative: '/',
	email: 'info@dierenartsjolanda.nl',
	linkedin: 'https://www.linkedin.com/in/jolandarietveld/',
	cv: 'https://www.dierenartsjolanda.nl/cv.pdf',
	kvk: '83566198',
	btw: '000.000.00000',
	months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'September', 'Oktober', 'November', 'December'],
};
