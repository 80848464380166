import '../styles/_components/Prices.scss';
import SeparatorSmall from './SeparatorSmall';
import RoundLogo from './assets/RoundLogo';

function Prices() {
	return <div className={`prices`}>
		<div className="prices__content">
			<div className="prices__item">
				<div className="prices__item__header">
					<h3>Eerste consult</h3>
					<strong className="price"><span>75,-</span></strong>
					<small>*introductietarief</small>
				</div>
				<div className="prices__item__separator">
					<div>
						<SeparatorSmall />
					</div>
				</div>
				<div className="prices__item__body">
					<ul className="blocked">
						<li>Intake via een uitgebreide vragenlijst</li>
						<li>Het doornemen van (medische) dossiers</li>
						<li>Overleg en adviesgesprek telefonisch of op locatie indien mogelijk</li>
						<li>Verslag orthomoleculair voedingsadvies op maat</li>
						<li>Begeleiding gedurende 3 maanden per e-mail</li>
					</ul>
				</div>
			</div>
			<div className="prices__item">
				<div className="prices__item__header">
					<h3>Vervolgconsult</h3>
					<strong className="price"><span>50,-</span></strong>
					<small>*introductietarief</small>
				</div>
				<div className="prices__item__logo">
					<RoundLogo color="white" />
				</div>
				<div className="prices__item__separator">
					<div>
						<SeparatorSmall />
					</div>
				</div>
				<div className="prices__item__body">
					<ul className="blocked">
						<li>Vragenlijst of overleg per e-mail of telefonisch</li>
						<li>Adviesgesprek indien nodig</li>
						<li>Verslag orthomoleculair voedingsadvies op maat</li>
						<li>Begeleiding gedurende 2 maanden per e-mail</li>
						<li>Een vervolgconsult kan worden gekozen, als verdere ondersteuning na de eerste 3 maanden nodig
							blijkt</li>
					</ul>
				</div>
			</div>
		</div>
	</div>;
}

export default Prices;
