import React from 'react';
import { NavLink } from 'react-router-dom';

import '../styles/_components/Menu.scss';
import RoundLogo from './assets/RoundLogo';

type MenuProps = {
	toggleMenu: any;
	menuButtonActive: boolean;
};

function Menu({ toggleMenu, menuButtonActive }: MenuProps) {
	return (
		<div className="menu">
			<div className="menu__logo">
				<span></span>
				<div>
					<RoundLogo />
				</div>
			</div>
			<div className="menu__intro"> </div>
			<div className="menu__items">
				<div className="menu__item">
					<NavLink to="/" className={({ isActive, isPending }) => (isPending ? 'menu__item__link is--loading' : isActive ? 'menu__item__link is--active' : 'menu__item__link')} onClick={toggleMenu}>
						<div className="menu__item__text">
							<span>Start</span>
						</div>
					</NavLink>
				</div>
				<div className="menu__item">
					<NavLink to="/advies" className={({ isActive, isPending }) => (isPending ? 'menu__item__link is--loading' : isActive ? 'menu__item__link is--active' : 'menu__item__link')} onClick={toggleMenu}>
						<div className="menu__item__text">
							<span>Advies</span>
						</div>
					</NavLink>
				</div>
				<div className="menu__item">
					<NavLink to="/overmij" className={({ isActive, isPending }) => (isPending ? 'menu__item__link is--loading' : isActive ? 'menu__item__link is--active' : 'menu__item__link')} onClick={toggleMenu}>
						<div className="menu__item__text">
							<span>Over mij</span>
						</div>
					</NavLink>
				</div>
				<div className="menu__item">
					<NavLink to="/contact" className={({ isActive, isPending }) => (isPending ? 'menu__item__link is--loading' : isActive ? 'menu__item__link is--active' : 'menu__item__link')} onClick={toggleMenu}>
						<div className="menu__item__text">
							<span>Contact</span>
						</div>
					</NavLink>
				</div>
			</div>
		</div>
	);
}

export default Menu;
