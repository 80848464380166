import { SETTINGS } from '../setup/config';

import Intro from '../components/Intro';
import AnimatedPage from './AnimatedPage';
import ContentBlocks from '../components/ContentBlocks';
import ContentBlock from '../components/ContentBlock';
import ContentSimple from '../components/ContentSimple';
import PhotoBlock from '../components/PhotoBlock';
import AnimatedImage from '../components/assets/AnimatedImage';
import Separator from '../components/Separator';

import Button from '../components/Button';
import { ButtonTypes } from '../setup/enum-buttontypes';

import IconLinkedin from '../components/assets/IconLinkedin';

function Overmij() {
	return (
		<AnimatedPage title={'Over mij'}>
			<Intro title="Ik ben Jolanda, dierenarts voor gezelschapsdieren" text="Al van jongs af aan wist ik dat ik 'iets met dieren' wilde gaan doen. Al gauw werd dat 'ik wil dierenarts worden'." logo={false} buttonText="Lees verder" hideButtonMobile={true} />
			<Separator logo={false} type={1} />
			<ContentBlocks>
				<div className="columnlayout__column">
					<ContentBlock
						className="contentblocks__contentblock1  contentblock--white contentblock--mt"
						button={<Button newTab={true} link={SETTINGS.linkedin} text={'Volg mij'} icon={<IconLinkedin />} type={ButtonTypes.ButtonIcon} />}
						content={
							<>
								<p>
									In 2015 ben ik afgestudeerd als <strong>Dierenarts voor gezelschapsdieren aan de Faculteit Diergeneeskunde</strong> van de Universiteit Utrecht. Ik heb sindsdien bij verschillende dierenartspraktijken in Noord-Holland gewerkt en waarnemingen gedaan. Tevens heb ik een periode buiten
									de praktijk gewerkt voor een dierenwelzijnsorganisatie.
								</p>
							</>
						}
					/>
					<ContentBlock
						className="contentblocks__contentblock2"
						content={
							<>
								<p>
									In 2019 kwam de <strong>veterinaire osteopathie</strong> op mijn pad, waarvan ik de opleiding in 2020 heb afgerond en ik deze manuele techniek ben gaan toepassen bij honden en katten. Al gauw kwam daar de opleiding tot <strong>veterinair chiropractor</strong> bij, waarvan ik de opleiding in 2022 heb
									afgerond. <br />
									<br />
									In 2023 heb ik de opleiding Fascia Manipulation (niveau 1) gevolgd en ben ik gestart met de opleiding tot <strong>orthomoleculair therapeut voor honden</strong>, welke ik in de zomer van 2024 heb afgerond. Eind 2024 en begin 2025 zal de Fascia Manipulation deel 2 en 3 volgen. Ik heb
									daarnaast ook ervaring met behandelingen in de Integrative Manual Therapy, lasertherapie, homeopathie en het inzetten van Bach bloesems.
								</p>
							</>
						}
					/>
				</div>
				<div className="columnlayout__column">
					<ContentBlock
						className="contentblocks__contentblock2 contentblock--white"
						image={<AnimatedImage />}
						content={
							<>
								<p>
									Mensen omschrijven mij als empathisch, gedreven, leergierig en een harde werker. Ik help graag, ben betrokken en zorgzaam. Ik werk zorgvuldig en graag samen met baasje en dier om tot de beste zorg te komen. Op deze manier zet ik mij met een{' '}
									<strong>holistische visie, vol passie en aandacht</strong> in voor de (preventieve) gezondheid van onze harige viervoeters en hun baasjes.
								</p>
							</>
						}
					/>
					<ContentBlock
						className="contentblocks__contentblock2"
						content={
							<>
								<p>
									Ik woon in <strong>Heemskerk, Noord-Holland</strong>, samen met mijn man, de twee liefste zoontjes, twee blije hondjes Milow en Xavi en de twee konijntjes Broer en Zus.
								</p>
							</>
						}
					/>
					<PhotoBlock className="photoblock__photoblock2" />
				</div>
			</ContentBlocks>
			<ContentSimple
				content={
					<>
						<p> Mijn werkgebied voor consulten op locatie is Noord-Holland (tot ca. 45 minuten vanaf Heemskerk). Mijn interesses liggen in de interne diergeneeskunde, voeding en orthomoleculaire therapie en de manuele therapieën bij honden en katten.</p>
					</>
				}
				button={<Button className="button--main" link={'/contact'} text={'Maak een afspraak'} type={ButtonTypes.ButtonIcon} />}
			/>
		</AnimatedPage>
	);
}

export default Overmij;
