import Lottie from 'lottie-react';
import introAnimation from '../animations/jd-intro.json';

import '../styles/_components/SplashScreen.scss';

type SplashScreenProps = {
	setIntroDone: any;
};

function SplashScreen({ setIntroDone }: SplashScreenProps) {
	return (
		<div className="splashscreen">
			<Lottie className="splashscreen__lottie" animationData={introAnimation} loop={0} onComplete={setIntroDone} />
		</div>
	);
}

export default SplashScreen;
