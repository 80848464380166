import React from 'react';

type RoundLogoProps = {
	color?: string;
};

function RoundLogo({ color }: RoundLogoProps) {
	return (
		<>
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 671.9 674" xmlSpace="preserve">
				<g>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M301.3,58.4l-4.2-42.7c0-0.4,0-0.7,0.3-0.9c0.2-0.2,0.6-0.4,1.3-0.4l6.1-0.6c2-0.2,3.9-0.1,5.5,0.4
		c1.6,0.5,3,1.4,4.1,2.8c1.2,1.4,2.1,3.3,2.9,5.8c0.8,2.5,1.4,5.6,1.7,9.5c0.5,4.6,0.6,8.5,0.3,11.6c-0.2,3.2-0.8,5.7-1.7,7.7
		c-0.9,1.9-2,3.4-3.5,4.4c-1.4,1-3.1,1.5-5,1.7L301.3,58.4z M305.9,53l2.8-0.3c0.8-0.1,1.6-0.4,2.3-0.9c0.7-0.5,1.3-1.5,1.8-2.9
		c0.5-1.4,0.8-3.5,0.8-6.1c0.1-2.7-0.1-6.2-0.5-10.5c-0.3-2.9-0.7-5.3-1.1-7.1c-0.5-1.9-1-3.3-1.6-4.3c-0.6-1-1.4-1.7-2.2-2.1
		c-0.8-0.3-1.8-0.5-2.8-0.4l-2.9,0.3L305.9,53z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M326.2,20.5c-0.8,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.6-0.8-3c0-1.1,0.2-2.1,0.7-2.8c0.4-0.7,1.2-1.1,2.2-1.1
		c1,0,1.6,0.2,2,0.7c0.4,0.5,0.6,1.5,0.6,2.8c0,1.3-0.2,2.3-0.6,3C327.9,20,327.2,20.4,326.2,20.5z M325.1,56.4L324,27.2
		c0-0.8,0.1-1.4,0.4-1.7c0.3-0.3,0.8-0.5,1.6-0.6s1.8-0.2,3.2-0.2l1.2,30.5c0,0.3-0.1,0.6-0.2,0.7c-0.1,0.1-0.6,0.2-1.3,0.3
		C328.1,56.2,326.9,56.3,325.1,56.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M344.6,56.8c-1.4,0-2.8-0.3-4-0.7c-1.2-0.4-2.3-1.2-3.2-2.2c-0.9-1.1-1.5-2.6-2-4.5c-0.4-1.9-0.6-4.4-0.6-7.5
		c0.1-3.9,0.4-7.1,1.1-9.5c0.7-2.4,1.5-4.2,2.6-5.4c1.1-1.2,2.3-2,3.6-2.4c1.4-0.4,2.7-0.6,4.1-0.6c2.6,0.1,4.6,0.9,5.9,2.6
		c1.3,1.7,1.9,4.3,1.9,7.7c0,1.4-0.1,2.8-0.2,4.3c-0.1,1.5-0.4,2.8-0.7,3.9c-1.4,0.3-3,0.4-4.8,0.5c-1.8,0.1-3.4,0.2-5,0.2
		c-1.6,0-3.4,0-5.5,0l0.1-4.2c1.6,0.1,3,0.1,4.1,0.1c1.2,0,2.3,0,3.5-0.1c1.2-0.1,2.2-0.2,3.1-0.5c0.1-0.6,0.2-1.2,0.3-1.9
		c0.1-0.7,0.1-1.3,0.1-1.9c0-2.3-0.2-3.9-0.7-4.8c-0.5-1-1.4-1.4-2.8-1.5c-0.7,0-1.4,0.2-2.1,0.6c-0.7,0.4-1.2,1.1-1.8,2
		c-0.5,1-0.9,2.3-1.2,4c-0.3,1.7-0.5,3.9-0.5,6.6c0,2,0,3.6,0.3,4.9c0.2,1.3,0.5,2.3,1,3c0.5,0.7,1.1,1.2,1.8,1.6
		c0.7,0.3,1.6,0.5,2.7,0.5c1.3,0,2.5-0.1,3.8-0.4c1.2-0.3,2.4-0.8,3.4-1.4c0.2,0.5,0.3,1.1,0.4,1.7c0.1,0.7,0.1,1.2,0.1,1.7
		c0,0.6-0.4,1.2-1.2,1.7c-0.8,0.5-1.9,0.9-3.2,1.2C347.6,56.7,346.2,56.8,344.6,56.8z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M357.5,56.6l2.5-29.2c1.3-0.8,2.7-1.3,4.2-1.7s3-0.5,4.6-0.4c1.6,0.1,2.8,0.5,3.4,1c0.6,0.5,0.9,1.2,0.8,2.1
		c0,0.3-0.1,0.8-0.2,1.3c-0.1,0.6-0.3,1.1-0.6,1.6c-0.4-0.3-0.9-0.5-1.4-0.7c-0.5-0.2-1-0.3-1.6-0.3c-0.7-0.1-1.4,0-2.2,0.2
		c-0.8,0.2-1.4,0.5-2.1,0.9l-2.2,24.7c0,0.3-0.1,0.6-0.3,0.7c-0.2,0.1-0.6,0.2-1.3,0.1C360.5,56.9,359.3,56.8,357.5,56.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M382.3,60.1c-1.4-0.2-2.7-0.6-3.9-1.2c-1.2-0.6-2.1-1.5-2.8-2.6c-0.7-1.2-1.2-2.8-1.4-4.7
		c-0.2-2,0-4.5,0.4-7.5c0.6-3.9,1.4-7,2.4-9.3c1-2.3,2.1-3.9,3.3-5c1.2-1,2.5-1.7,3.9-1.9c1.4-0.2,2.8-0.2,4.1,0
		c2.6,0.4,4.5,1.5,5.5,3.4c1.1,1.9,1.3,4.5,0.8,7.8c-0.2,1.4-0.5,2.8-0.8,4.2c-0.3,1.4-0.7,2.7-1.2,3.8c-1.5,0.1-3.1,0-4.8-0.1
		c-1.8-0.1-3.4-0.3-5-0.5c-1.6-0.2-3.4-0.4-5.4-0.7l0.7-4.1c1.6,0.3,2.9,0.5,4.1,0.7c1.1,0.2,2.3,0.3,3.5,0.4
		c1.2,0.1,2.2,0.1,3.1-0.1c0.2-0.6,0.4-1.2,0.5-1.8c0.1-0.7,0.3-1.3,0.4-1.9c0.3-2.2,0.3-3.9,0-4.9c-0.3-1-1.2-1.6-2.6-1.8
		c-0.7-0.1-1.4,0-2.1,0.3c-0.7,0.3-1.4,0.9-2,1.8c-0.6,0.9-1.2,2.2-1.8,3.8c-0.5,1.7-1,3.8-1.4,6.5c-0.3,2-0.4,3.6-0.4,4.9
		c0,1.3,0.2,2.4,0.6,3.2c0.4,0.8,0.9,1.4,1.6,1.8c0.7,0.4,1.5,0.7,2.6,0.9c1.2,0.2,2.5,0.2,3.8,0.1c1.3-0.1,2.5-0.4,3.6-0.9
		c0.1,0.5,0.2,1.1,0.1,1.8c0,0.7-0.1,1.2-0.2,1.7c-0.1,0.6-0.6,1.1-1.5,1.6c-0.9,0.4-2,0.7-3.3,0.8S383.9,60.3,382.3,60.1z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M395,61.6l7-29.2c0.9-0.2,1.9-0.3,3.1-0.3c1.2,0,2.4,0,3.6,0.1c1.2,0.1,2.4,0.2,3.5,0.5c2.1,0.5,3.8,1.2,4.9,2
		c1.1,0.9,1.8,1.9,2.1,3.2c0.2,1.3,0.1,3-0.3,5L413.5,65c-0.1,0.3-0.2,0.5-0.4,0.6c-0.2,0.1-0.6,0.1-1.3-0.1
		c-0.7-0.1-1.9-0.4-3.6-0.8l5.2-21.6c0.5-1.9,0.6-3.3,0.3-4.3c-0.3-0.9-1.1-1.6-2.6-1.9c-0.7-0.2-1.5-0.3-2.4-0.3
		c-0.9,0-1.7,0.1-2.5,0.3l-5.9,24.8c-0.1,0.3-0.2,0.5-0.4,0.6c-0.2,0.1-0.6,0.1-1.3-0.1C397.8,62.2,396.6,62,395,61.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M425,70.3c-1.1-0.4-2.2-0.8-3.1-1.3s-1.7-1.2-2.3-2.1c-0.6-0.9-0.9-2-1-3.4c-0.1-1.4,0.2-3.1,0.9-5.1
		c0.7-2.1,1.4-3.7,2.3-4.8c0.9-1.2,1.8-2,2.8-2.4c1-0.5,2-0.6,3-0.6c1,0.1,2.1,0.3,3.1,0.6c0.6,0.2,1.4,0.5,2.2,0.9
		c0.8,0.4,1.5,0.8,2,1.3c0.4-1.1,0.7-2,0.9-2.8c0.2-0.7,0.5-1.4,0.6-1.9c0.3-1,0.4-1.7,0.3-2.3c-0.1-0.6-0.4-1.1-0.9-1.5
		c-0.5-0.4-1.1-0.7-1.9-1c-1.6-0.5-3.1-0.9-4.6-1c-1.5-0.2-2.8-0.2-3.8-0.2c-0.1-0.6-0.1-1.1-0.1-1.6c0.1-0.5,0.2-1,0.3-1.4
		c0.1-0.3,0.2-0.6,0.4-0.8c0.2-0.3,0.5-0.5,0.7-0.6c0.6-0.2,1.8-0.1,3.6,0c1.8,0.2,3.8,0.6,6.1,1.3c2.6,0.8,4.3,2,5.1,3.5
		s0.8,3.6-0.1,6.3l-1,3.2c-0.7,2.3-1.5,4.6-2.3,6.8c-0.8,2.3-1.5,4.4-2.2,6.4c-0.7,2-1.4,3.7-2,5.2c-1,0.2-2.2,0.3-3.7,0.3
		C428.7,71.3,426.9,70.9,425,70.3z M426.8,66.4c0.6,0.2,1.3,0.3,2,0.3c0.7,0,1.2-0.1,1.5-0.3c0.3-0.6,0.6-1.5,1-2.6
		c0.4-1.1,0.8-2.3,1.2-3.5c0.4-1.2,0.8-2.4,1.2-3.5c-0.3-0.3-0.8-0.6-1.3-0.9c-0.5-0.3-1-0.4-1.4-0.6c-0.6-0.2-1.2-0.3-1.8-0.4
		c-0.6,0-1.1,0.1-1.7,0.4c-0.5,0.3-1.1,0.8-1.6,1.5c-0.5,0.7-1,1.7-1.4,3.1c-0.4,1.1-0.5,2-0.6,2.7c0,0.7,0.1,1.4,0.3,1.9
		c0.2,0.5,0.6,0.9,1,1.2C425.7,65.9,426.2,66.2,426.8,66.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M438.1,74.2L449,47c1.5-0.4,3-0.5,4.5-0.4c1.6,0.1,3,0.4,4.5,1c1.5,0.6,2.5,1.2,2.9,1.9
		c0.4,0.7,0.5,1.4,0.2,2.2c-0.1,0.3-0.3,0.7-0.6,1.2c-0.3,0.5-0.6,1-1,1.4c-0.3-0.4-0.7-0.8-1.1-1.1c-0.4-0.3-0.9-0.6-1.4-0.8
		c-0.7-0.3-1.4-0.4-2.1-0.5c-0.8-0.1-1.5,0-2.2,0.2l-9.3,23c-0.1,0.3-0.3,0.5-0.5,0.6c-0.2,0.1-0.6,0-1.3-0.2
		C440.8,75.2,439.7,74.8,438.1,74.2z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M460.3,84.5c-1.6-0.8-2.6-1.7-3.1-2.7c-0.4-1.1-0.5-2.3-0.1-3.7c0.4-1.4,1-2.9,1.8-4.6l7.9-16.6l-3.9-1.8
		c0.6-1.2,1-2.1,1.3-2.6c0.3-0.5,0.5-0.9,0.8-1c0.2-0.1,0.5-0.1,0.8,0.1l2.9,1.4l4.1-8.7c0.3-0.6,0.6-0.9,0.9-1
		c0.3-0.1,0.9,0,1.5,0.3c0.7,0.3,1.6,0.7,2.9,1.3l-4.9,10.2l5.8,2.8c-0.6,1.2-1,2-1.3,2.6c-0.3,0.6-0.5,0.9-0.8,1
		c-0.2,0.1-0.5,0.1-0.8-0.1l-4.9-2.3l-7.9,16.6c-0.4,0.8-0.7,1.6-1,2.3c-0.3,0.7-0.4,1.4-0.3,1.9c0.1,0.5,0.5,1,1.2,1.3
		c0.6,0.3,1.2,0.5,1.8,0.6c0.6,0.1,1.2,0.1,1.9,0.1c-0.1,0.3-0.2,0.7-0.3,1.1c-0.1,0.4-0.3,0.9-0.5,1.4c-0.3,0.7-0.8,1.1-1.5,1.2
		c-0.7,0.1-1.4,0.1-2.2-0.2C461.9,85.2,461.1,84.9,460.3,84.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M474.3,91.8c-1.9-1.1-3.4-2.2-4.4-3.4c-1-1.2-1.2-2.2-0.7-3.2c0.2-0.3,0.5-0.7,0.8-1.1
		c0.4-0.4,0.8-0.7,1.4-1.1c0.5,0.9,1.2,1.8,2,2.5c0.8,0.7,1.7,1.4,2.8,2c1.4,0.8,2.6,1.1,3.7,1c1.1-0.1,2-0.8,2.6-1.9
		c0.4-0.7,0.7-1.5,0.7-2.4c0.1-0.9-0.1-1.9-0.5-3.1l-2.8-7.6c-0.7-1.7-0.9-3.2-0.8-4.4c0.1-1.2,0.4-2.3,1-3.2
		c0.7-1.3,1.7-2.2,2.9-2.8c1.2-0.6,2.6-0.8,4.2-0.6c1.6,0.2,3.5,0.9,5.5,2c1.9,1.1,3.2,2.1,4,3c0.8,0.9,1.2,1.7,1.3,2.4
		c0.1,0.7,0.1,1.2-0.1,1.6c-0.4,0.8-1.2,1.3-2.3,1.7c-0.5-1-1.2-1.8-1.9-2.5s-1.6-1.4-2.7-1.9c-1.3-0.7-2.5-1.1-3.5-1.1
		c-1,0-1.8,0.4-2.3,1.3c-0.3,0.5-0.4,1-0.4,1.7c0,0.7,0.2,1.6,0.7,2.9l2.8,7c0.6,1.8,0.8,3.4,0.7,4.7c-0.1,1.3-0.5,2.6-1.3,4
		c-1.4,2.4-3.2,3.8-5.6,4.3C479.7,93.9,477.1,93.4,474.3,91.8z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M497.7,106.3c-1.6-1.2-2.8-2.2-3.3-3.1c-0.6-0.9-0.8-1.7-0.7-2.4c0.1-0.7,0.4-1.4,0.8-2
		c0.5-0.8,1.1-1.3,1.6-1.7c0.5-0.4,1-0.7,1.4-0.9c0.2,0.7,0.6,1.5,1.1,2.3c0.5,0.9,1.2,1.6,1.9,2.1c0.8,0.5,1.4,0.8,2,0.8
		c0.6,0,1.1-0.3,1.8-0.9c0.6-0.6,1.4-1.5,2.3-2.8l17-24.1c0.6-0.9,1.1-1.4,1.5-1.6c0.4-0.2,1-0.1,1.9,0.4c0.9,0.5,2.3,1.4,4.2,2.8
		L511.6,103c-1,1.4-2,2.6-3.1,3.5c-1,0.9-2.1,1.5-3.3,1.8c-1.1,0.3-2.3,0.3-3.6,0C500.4,107.9,499.1,107.3,497.7,106.3z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M515.1,119.5c-1.3-1.1-2.3-2.1-3-3.3c-0.7-1.1-1-2.4-0.9-3.8c0.1-1.4,0.7-3.1,1.7-5c1.1-1.9,2.7-4.3,4.9-7
		c3.6-4.4,6.9-7.1,10-7.9c3-0.8,5.9-0.2,8.4,1.9c1.8,1.4,2.9,3,3.5,4.6c0.6,1.6,0.4,3.5-0.6,5.8c-0.9,2.2-2.7,5-5.4,8.3
		c-3.9,4.7-7.3,7.5-10.2,8.3C520.5,122.3,517.7,121.6,515.1,119.5z M518.9,115.1c0.3,0.3,0.8,0.3,1.3,0.2c0.5-0.1,1.4-0.7,2.5-1.7
		c1.1-1,2.7-2.8,4.8-5.3c1.9-2.3,3.3-4.1,4.1-5.4c0.8-1.2,1.3-2.1,1.3-2.7c0-0.6-0.2-1.1-0.7-1.5c-0.4-0.3-0.8-0.4-1.4-0.2
		c-0.6,0.1-1.5,0.7-2.6,1.7c-1.1,1-2.6,2.7-4.5,5c-1.5,1.9-2.7,3.4-3.6,4.6c-0.8,1.2-1.4,2.1-1.7,2.7c-0.3,0.6-0.4,1.1-0.3,1.5
		C518.5,114.5,518.7,114.8,518.9,115.1z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M525.5,127.5l29.2-31.8c0.7-0.8,1.3-1.2,1.9-1.2c0.6-0.1,1.2,0.2,2.1,0.9c0.8,0.6,1.9,1.6,3.3,2.9l-30.5,33.2
		c-0.3,0.3-0.6,0.5-0.8,0.5c-0.3,0-0.8-0.3-1.5-1C528.4,130.2,527.2,129.1,525.5,127.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M538.6,140.9c-0.8-0.8-1.5-1.7-2.1-2.6c-0.6-0.9-0.9-1.9-1.1-3c-0.1-1.1,0.1-2.2,0.7-3.5
		c0.6-1.3,1.7-2.6,3.2-4.2c1.5-1.5,2.9-2.6,4.2-3.2c1.3-0.7,2.4-1,3.4-1c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.5,1,2.2,1.6
		c0.3,0.3,0.7,0.8,1.2,1.5c0.5,0.7,0.8,1.4,0.9,2c0.7-0.7,1.3-1.2,1.8-1.7c0.5-0.5,0.9-0.9,1.4-1.3c0.4-0.4,0.6-0.7,0.8-1.1
		c0.1-0.4,0.1-0.7,0-1.1c-0.1-0.4-0.4-0.8-0.9-1.3c-0.8-0.8-1.8-1.7-3-2.5c-1.2-0.8-2.2-1.5-3.2-2c0.2-0.8,0.6-1.5,1.1-2.1
		c0.5-0.6,0.9-1.1,1.2-1.5c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.3,1.2-0.2c0.6,0.1,1.6,0.6,3,1.4c1.5,0.9,3.1,2.2,4.8,4
		c2,2.1,3,3.9,3.1,5.6c0,1.6-1,3.5-3,5.5l-2.1,2.1c-1.7,1.7-3.5,3.4-5.3,5.1c-1.8,1.8-3.5,3.4-5.1,4.9c-1.6,1.5-3,2.8-4.2,3.8
		c-1.2-0.3-2.4-0.9-3.8-1.8C541.2,143.4,539.9,142.3,538.6,140.9z M543.1,137.3c0.2,0.2,0.5,0.4,0.8,0.6c0.3,0.2,0.6,0.2,0.9,0.2
		c0.4-0.3,0.9-0.8,1.6-1.4c0.7-0.6,1.4-1.3,2.2-2.1c0.8-0.8,1.5-1.5,2.1-2.1c0-0.3-0.1-0.6-0.3-0.9s-0.3-0.5-0.5-0.7
		c-0.2-0.2-0.5-0.4-0.8-0.5c-0.3-0.1-0.6-0.1-1,0.1s-0.9,0.4-1.4,0.8c-0.5,0.4-1.2,0.9-1.9,1.6c-0.5,0.5-1,1-1.3,1.4
		c-0.3,0.4-0.6,0.8-0.7,1.1c-0.2,0.4-0.2,0.7-0.1,1C542.6,136.7,542.8,137,543.1,137.3z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M547.6,149.5l23.1-19.3c0.7,0.3,1.5,0.8,2.5,1.5c1,0.7,1.9,1.4,2.9,2.2c1,0.8,1.8,1.7,2.5,2.5
		c1.4,1.6,2.3,3.1,2.8,4.5c0.5,1.3,0.5,2.6,0,3.8c-0.5,1.2-1.6,2.5-3.2,3.9l-17.5,14.6c-0.3,0.3-0.6,0.4-0.9,0.3
		c-0.3-0.1-0.8-0.5-1.4-1.2c-0.7-0.7-1.8-2-3.3-3.9l16.7-14c1.3-1.1,2-1.9,2.3-2.5c0.3-0.6,0.2-1.2-0.4-1.9c-0.3-0.3-0.6-0.6-1-0.8
		c-0.4-0.2-0.8-0.4-1.2-0.5l-18.5,15.4c-0.3,0.3-0.6,0.4-0.9,0.3s-0.7-0.4-1.4-1.2C550.2,152.5,549.1,151.2,547.6,149.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M566.5,174.4c-0.8-1.1-1.4-2.2-1.8-3.4c-0.4-1.2-0.5-2.4-0.3-3.8c0.3-1.4,1-2.9,2.4-4.5c1.3-1.6,3.3-3.4,6-5.3
		c3.5-2.5,6.6-4.2,9.1-5.1c2.6-0.9,4.7-1.2,6.4-0.8c1.7,0.4,3,1.2,4,2.6c0.5,0.7,0.8,1.3,1,2c0.2,0.6,0.3,1.2,0.2,1.7l10-7.1
		c0.9-0.6,1.6-0.9,2.1-0.8c0.5,0,1.2,0.5,1.8,1.3c0.7,0.8,1.6,2,2.7,3.5l-36.7,26.1c-1-0.6-2.1-1.4-3.3-2.4
		C568.9,177.4,567.7,176.1,566.5,174.4z M571.6,170.9c0.2,0.3,0.5,0.6,0.8,0.8c0.3,0.2,0.6,0.4,0.9,0.5l15.9-11.3
		c0-0.2,0-0.4-0.2-0.7c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.2-0.4-0.5-0.8-0.6c-0.3-0.2-0.9-0.2-1.7-0.1c-0.8,0.1-1.8,0.5-3.1,1.2
		c-1.3,0.7-3,1.8-5.1,3.3c-1.5,1.1-2.8,2-3.8,2.8c-1,0.8-1.7,1.5-2.1,2c-0.5,0.6-0.7,1.1-0.8,1.5
		C571.3,170.1,571.4,170.5,571.6,170.9z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M577.8,191.5c-0.6-1-1.1-2-1.5-3c-0.4-1-0.5-2.1-0.3-3.1c0.1-1.1,0.6-2.1,1.5-3.2c0.9-1.1,2.2-2.2,4.1-3.3
		c1.8-1.1,3.4-1.8,4.8-2.2c1.4-0.4,2.6-0.4,3.6-0.2c1,0.2,1.8,0.6,2.5,1.2c0.7,0.6,1.3,1.3,1.7,2.1c0.2,0.4,0.5,1,0.8,1.8
		c0.3,0.8,0.5,1.5,0.4,2.2c0.8-0.5,1.5-0.9,2.1-1.3s1.1-0.7,1.6-1c0.5-0.3,0.8-0.6,1-0.9c0.2-0.3,0.3-0.7,0.2-1.1
		c-0.1-0.4-0.3-0.9-0.6-1.5c-0.6-1-1.4-2-2.3-3.1c-0.9-1.1-1.8-2-2.7-2.7c0.4-0.7,0.9-1.3,1.5-1.8c0.6-0.5,1.1-0.9,1.5-1.1
		c0.3-0.2,0.7-0.3,1.2-0.4c0.5-0.1,0.8-0.1,1.2,0.1c0.5,0.2,1.4,0.9,2.6,2.1c1.2,1.2,2.5,2.8,3.8,5c1.5,2.5,2.1,4.5,1.7,6.1
		s-1.8,3.2-4.2,4.6l-2.5,1.5c-2,1.2-4.2,2.5-6.3,3.8c-2.2,1.3-4.2,2.5-6.1,3.6c-1.9,1.1-3.6,2-5,2.7c-1.1-0.6-2.2-1.4-3.3-2.6
		C579.8,194.5,578.7,193.2,577.8,191.5z M583,189c0.2,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.5,0.4,0.8,0.4c0.4-0.2,1-0.5,1.9-1
		c0.8-0.5,1.7-1,2.6-1.6c0.9-0.6,1.8-1.1,2.5-1.5c0.1-0.3,0.1-0.6-0.1-0.9c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.3-0.4-0.5-0.7-0.7
		c-0.3-0.2-0.6-0.2-1-0.2c-0.4,0-0.9,0.2-1.5,0.4c-0.6,0.2-1.3,0.6-2.2,1.2c-0.6,0.4-1.2,0.7-1.6,1.1c-0.4,0.3-0.7,0.6-1,1
		c-0.2,0.3-0.3,0.6-0.3,0.9C582.7,188.3,582.8,188.7,583,189z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M588.9,210.1l38.9-18c0.3-0.2,0.7-0.2,0.9-0.1c0.3,0.1,0.6,0.5,0.9,1.1l2.6,5.5c0.9,1.9,1.3,3.6,1.4,5.3
		c0.1,1.7-0.3,3.3-1.3,4.8c-0.9,1.5-2.4,3.1-4.6,4.6c-2.1,1.6-4.9,3.1-8.4,4.7c-4.2,1.9-7.8,3.3-10.9,4.1c-3.1,0.8-5.7,1.1-7.8,0.9
		c-2.1-0.2-3.9-0.8-5.3-1.8c-1.4-1-2.5-2.4-3.3-4.2L588.9,210.1z M595.5,212.7l1.2,2.6c0.4,0.8,0.9,1.4,1.6,1.9s1.9,0.7,3.3,0.7
		c1.5,0,3.5-0.4,6.1-1.2c2.6-0.8,5.8-2.1,9.8-3.9c2.6-1.2,4.7-2.3,6.3-3.4c1.6-1,2.8-2,3.6-3c0.8-0.9,1.2-1.9,1.2-2.8
		c0-0.9-0.2-1.8-0.6-2.8l-1.2-2.6L595.5,212.7z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M598.6,231.9l27.2-10.6c0.7-0.3,1.3-0.4,1.7-0.2c0.4,0.2,0.8,0.6,1.1,1.3c0.3,0.7,0.8,1.7,1.3,3l-28.4,11.1
		c-0.3,0.1-0.6,0.1-0.7,0c-0.2-0.1-0.4-0.5-0.7-1.1C599.7,234.6,599.3,233.5,598.6,231.9z M632.9,221.2c-0.3-0.8-0.2-1.5,0.2-2.1
		c0.4-0.6,1.2-1.2,2.6-1.8c1.1-0.4,2-0.5,2.8-0.3c0.8,0.2,1.4,0.7,1.8,1.7c0.4,0.9,0.3,1.6,0,2.2c-0.4,0.6-1.2,1.1-2.5,1.5
		c-1.2,0.5-2.2,0.6-3.1,0.4C633.9,222.6,633.3,222.1,632.9,221.2z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M604.6,250.4c-0.4-1.4-0.7-2.7-0.7-4c0-1.3,0.4-2.5,1.1-3.7c0.7-1.2,1.9-2.3,3.6-3.4c1.7-1.1,4-2.1,6.9-3
		c3.8-1.2,6.9-1.9,9.3-2s4.4,0.1,5.9,0.7c1.5,0.6,2.6,1.5,3.4,2.7c0.8,1.1,1.4,2.4,1.9,3.7c0.8,2.5,0.6,4.7-0.6,6.5
		c-1.2,1.8-3.4,3.2-6.6,4.3c-1.3,0.4-2.7,0.8-4.1,1.2c-1.4,0.4-2.7,0.6-3.9,0.6c-0.7-1.3-1.4-2.7-2.1-4.4c-0.7-1.6-1.3-3.2-1.8-4.7
		c-0.6-1.5-1.1-3.2-1.8-5.2l4-1.2c0.5,1.5,0.9,2.8,1.2,3.9c0.4,1.1,0.8,2.2,1.3,3.3c0.5,1.1,1,2,1.5,2.7c0.6-0.1,1.2-0.2,1.9-0.3
		c0.7-0.2,1.3-0.3,1.8-0.5c2.1-0.7,3.6-1.5,4.3-2.2c0.8-0.8,0.9-1.8,0.5-3.1c-0.2-0.7-0.6-1.3-1.2-1.8c-0.6-0.5-1.4-0.8-2.5-1
		c-1.1-0.2-2.5-0.1-4.2,0.1s-3.9,0.8-6.4,1.6c-1.9,0.6-3.4,1.2-4.6,1.8c-1.2,0.6-2,1.3-2.5,2c-0.5,0.7-0.8,1.4-0.9,2.2
		c-0.1,0.8,0.1,1.7,0.4,2.7c0.4,1.2,0.9,2.3,1.6,3.4c0.7,1.1,1.5,2,2.4,2.8c-0.4,0.4-0.9,0.7-1.5,0.9c-0.6,0.3-1.1,0.5-1.6,0.6
		c-0.6,0.2-1.3,0-2-0.6c-0.8-0.6-1.5-1.5-2.2-2.6C605.7,253.3,605.1,251.9,604.6,250.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M609,262.6l28.4-7.1c1.2,1,2.1,2.1,3,3.4c0.8,1.3,1.5,2.7,1.8,4.2c0.4,1.6,0.5,2.8,0.2,3.5
		c-0.3,0.7-0.8,1.2-1.7,1.4c-0.3,0.1-0.8,0.2-1.3,0.2c-0.6,0.1-1.1,0-1.7,0c0.1-0.5,0.2-1,0.2-1.5s-0.1-1-0.2-1.6
		c-0.2-0.7-0.5-1.4-0.9-2c-0.4-0.7-0.9-1.2-1.5-1.7l-24.1,6c-0.3,0.1-0.6,0.1-0.7,0c-0.2-0.1-0.3-0.5-0.6-1.2
		C609.7,265.5,609.4,264.3,609,262.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M613.7,287.1c-0.3-1.4-0.3-2.8-0.1-4c0.2-1.3,0.7-2.5,1.6-3.5c0.9-1.1,2.2-2,4-2.8c1.8-0.8,4.2-1.5,7.3-2
		c3.9-0.7,7.1-0.9,9.5-0.7c2.5,0.2,4.4,0.7,5.8,1.5c1.4,0.8,2.4,1.9,3.1,3.1c0.6,1.2,1.1,2.6,1.3,3.9c0.5,2.6,0,4.7-1.4,6.3
		c-1.4,1.6-3.8,2.7-7.1,3.3c-1.4,0.2-2.8,0.4-4.2,0.6c-1.5,0.2-2.8,0.2-4,0.1c-0.5-1.4-1-2.9-1.5-4.6c-0.4-1.7-0.8-3.4-1.2-4.9
		c-0.3-1.6-0.7-3.4-1.1-5.4l4.1-0.7c0.2,1.6,0.5,2.9,0.7,4.1c0.2,1.1,0.5,2.3,0.8,3.4c0.3,1.2,0.7,2.1,1.1,2.9c0.6,0,1.2,0,1.9-0.1
		c0.7-0.1,1.3-0.2,1.9-0.3c2.2-0.4,3.8-1,4.6-1.6c0.8-0.6,1.1-1.6,0.9-3c-0.1-0.7-0.4-1.3-1-1.9c-0.5-0.6-1.3-1-2.4-1.3
		c-1.1-0.3-2.5-0.5-4.2-0.4c-1.7,0-3.9,0.3-6.6,0.8c-1.9,0.3-3.5,0.7-4.8,1.2c-1.2,0.5-2.2,1-2.8,1.6c-0.6,0.6-1,1.3-1.2,2.1
		c-0.2,0.8-0.2,1.7,0,2.7c0.2,1.2,0.6,2.4,1.2,3.6c0.5,1.2,1.2,2.2,2,3.1c-0.5,0.3-1,0.5-1.6,0.7c-0.6,0.2-1.2,0.3-1.7,0.4
		c-0.6,0.1-1.3-0.2-1.9-0.9c-0.7-0.7-1.3-1.7-1.8-2.9C614.4,290.1,614,288.7,613.7,287.1z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M616.4,299.6l29.8-2.9c0.5,0.8,0.9,1.7,1.3,2.8c0.4,1.1,0.8,2.2,1.1,3.4c0.3,1.2,0.5,2.4,0.7,3.5
		c0.2,2.2,0.1,3.9-0.3,5.3c-0.4,1.4-1.2,2.4-2.4,3c-1.2,0.7-2.8,1.1-4.8,1.3l-22.6,2.2c-0.3,0-0.6,0-0.7-0.2
		c-0.1-0.2-0.3-0.6-0.4-1.3c-0.1-0.7-0.2-1.9-0.4-3.7l22.1-2.1c2-0.2,3.3-0.6,4.1-1.1c0.8-0.5,1.1-1.6,1-3.1
		c-0.1-0.7-0.2-1.5-0.5-2.4s-0.6-1.6-1.1-2.2l-25.4,2.5c-0.3,0-0.6,0-0.7-0.2c-0.1-0.1-0.3-0.6-0.4-1.3
		C616.7,302.5,616.6,301.3,616.4,299.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M618,330.8c0-1.2,0.1-2.3,0.2-3.4c0.2-1.1,0.6-2,1.2-2.9c0.6-0.9,1.6-1.5,2.8-2.1s3-0.8,5.2-0.8
		c2.2,0,4,0.2,5.3,0.6c1.4,0.4,2.4,1,3.2,1.8c0.7,0.8,1.3,1.7,1.5,2.7c0.3,1,0.4,2,0.4,3.1c0,0.7,0,1.5-0.1,2.4
		c-0.1,0.9-0.3,1.7-0.6,2.3c1.2,0,2.2,0,2.9-0.1c0.8,0,1.5,0,2,0c1,0,1.8-0.2,2.3-0.5c0.5-0.3,0.9-0.8,1.1-1.3
		c0.2-0.6,0.3-1.3,0.3-2.1c0-1.6-0.2-3.2-0.5-4.7c-0.3-1.5-0.7-2.7-1.1-3.6c0.5-0.3,1-0.5,1.5-0.6c0.5-0.1,1-0.1,1.4-0.2
		c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.3,0.8,0.5c0.3,0.5,0.7,1.7,1.2,3.4c0.4,1.7,0.7,3.8,0.7,6.2c0,2.7-0.5,4.7-1.6,6
		c-1.1,1.3-3.1,1.9-6,2l-3.3,0.1c-2.4,0-4.8,0.1-7.2,0.1c-2.4,0-4.7,0-6.8,0c-2.1,0-4-0.1-5.6-0.2c-0.5-0.8-1-2-1.5-3.4
		C618.2,334.6,618,332.8,618,330.8z M622.2,331.2c0,0.7,0.1,1.3,0.3,1.9c0.2,0.6,0.5,1.1,0.8,1.3c0.7,0.1,1.6,0.1,2.8,0.1
		c1.2,0,2.4,0,3.7,0c1.3,0,2.5,0,3.7,0c0.2-0.4,0.3-0.9,0.4-1.5c0.1-0.6,0.1-1.1,0.1-1.5c0-0.7-0.1-1.3-0.3-1.9s-0.5-1.1-0.9-1.5
		c-0.4-0.4-1.1-0.7-1.9-1c-0.8-0.2-2-0.3-3.3-0.3c-1.1,0-2.1,0.1-2.8,0.3c-0.7,0.2-1.3,0.5-1.7,0.9c-0.4,0.4-0.6,0.8-0.8,1.3
		C622.3,330,622.2,330.6,622.2,331.2z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M618.6,344.5l29.3,1.4c0.8,1.3,1.4,2.6,1.9,4.1s0.6,3,0.5,4.6c-0.1,1.6-0.4,2.8-0.8,3.4
		c-0.5,0.6-1.1,0.9-2,0.9c-0.3,0-0.8-0.1-1.3-0.2c-0.6-0.1-1.1-0.3-1.6-0.5c0.3-0.4,0.5-0.9,0.6-1.4c0.2-0.5,0.2-1,0.3-1.6
		c0-0.7,0-1.4-0.2-2.2c-0.2-0.7-0.5-1.4-0.9-2l-24.8-1.3c-0.3,0-0.6-0.1-0.7-0.3c-0.1-0.2-0.2-0.6-0.2-1.3
		C618.4,347.4,618.5,346.2,618.6,344.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M616.1,368.9c0.2-1.8,0.7-3,1.6-3.8c0.9-0.8,2-1.2,3.4-1.3c1.4-0.1,3.1,0,5,0.2l18.2,2l0.5-4.3
		c1.3,0.1,2.3,0.3,2.9,0.3s1,0.2,1.2,0.4c0.2,0.2,0.2,0.4,0.2,0.8l-0.3,3.2l9.5,1c0.6,0.1,1,0.2,1.2,0.5c0.2,0.3,0.3,0.8,0.2,1.5
		c0,0.7-0.1,1.8-0.3,3.2l-11.3-1.2l-0.7,6.4c-1.3-0.1-2.3-0.3-2.9-0.3c-0.6-0.1-1-0.2-1.2-0.4c-0.2-0.2-0.2-0.4-0.2-0.8l0.6-5.4
		l-18.2-2c-0.9-0.1-1.7-0.2-2.5-0.2s-1.4,0.1-1.9,0.4c-0.5,0.3-0.7,0.8-0.8,1.5c-0.1,0.7-0.1,1.3,0,1.9c0.1,0.6,0.3,1.2,0.5,1.8
		c-0.3,0-0.7,0.1-1.2,0.1c-0.4,0-0.9,0-1.5,0c-0.8-0.1-1.3-0.4-1.6-1s-0.5-1.3-0.6-2.2C616,370.6,616,369.8,616.1,368.9z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M613.8,384.5c0.4-2.2,1-4,1.7-5.3c0.8-1.3,1.7-1.9,2.8-1.7c0.4,0.1,0.8,0.2,1.3,0.4c0.5,0.2,1,0.5,1.5,1
		c-0.7,0.8-1.3,1.7-1.7,2.7c-0.4,1-0.8,2.1-1,3.2c-0.3,1.6-0.2,2.9,0.3,3.9c0.5,1,1.4,1.6,2.6,1.9c0.8,0.1,1.7,0.1,2.5-0.1
		s1.8-0.7,2.8-1.5l6.2-5.1c1.4-1.2,2.7-1.9,3.9-2.2c1.2-0.3,2.3-0.3,3.3-0.1c1.4,0.3,2.7,0.8,3.6,1.8c1,0.9,1.6,2.2,2,3.8
		c0.3,1.6,0.3,3.6-0.1,5.8c-0.4,2.1-0.9,3.7-1.5,4.8c-0.6,1-1.2,1.7-1.8,2c-0.6,0.3-1.1,0.5-1.6,0.4c-0.9-0.2-1.6-0.7-2.3-1.6
		c0.7-0.8,1.3-1.7,1.8-2.6c0.4-0.9,0.7-2,1-3.2c0.3-1.5,0.2-2.7-0.1-3.7c-0.4-1-1-1.6-2-1.7c-0.5-0.1-1.1,0-1.8,0.2
		s-1.5,0.7-2.5,1.6L629,394c-1.5,1.1-2.9,1.9-4.2,2.2c-1.3,0.3-2.7,0.4-4.2,0.1c-2.7-0.5-4.7-1.8-5.9-3.9S613.2,387.7,613.8,384.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M607.8,411.4c0.6-1.9,1.2-3.3,1.8-4.2c0.7-0.8,1.3-1.3,2.1-1.5c0.7-0.1,1.4-0.1,2.1,0.1c0.9,0.3,1.6,0.6,2.1,1
		c0.5,0.4,1,0.7,1.3,1c-0.6,0.4-1.2,1.1-1.8,1.8c-0.6,0.8-1.1,1.6-1.3,2.5c-0.3,0.9-0.3,1.6-0.1,2.1c0.2,0.5,0.6,1,1.4,1.4
		c0.8,0.4,1.9,0.8,3.4,1.2l28.3,8.1c1,0.3,1.7,0.6,2,0.9c0.3,0.3,0.4,1,0.2,1.9c-0.2,1-0.6,2.6-1.3,4.9l-32.6-9.3
		c-1.7-0.5-3.1-1.1-4.3-1.8c-1.2-0.7-2.1-1.5-2.8-2.5s-1-2.1-1.1-3.4C607.2,414.5,607.3,413,607.8,411.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M601.1,432.1c0.6-1.6,1.3-2.9,2.1-3.9c0.8-1,1.9-1.8,3.3-2.1c1.4-0.4,3.1-0.4,5.3,0c2.2,0.4,4.9,1.2,8.2,2.4
		c5.4,2,8.9,4.3,10.7,6.9c1.8,2.6,2.1,5.5,1,8.6c-0.8,2.1-1.8,3.7-3.2,4.8c-1.3,1-3.2,1.5-5.7,1.3c-2.4-0.2-5.6-1-9.6-2.4
		c-5.7-2.1-9.5-4.4-11.2-6.9C600.2,438.1,599.9,435.3,601.1,432.1z M606.5,434.3c-0.1,0.4-0.1,0.8,0.2,1.3c0.3,0.5,1.1,1.1,2.4,1.8
		c1.3,0.7,3.5,1.7,6.6,2.8c2.8,1,5,1.8,6.4,2.2c1.4,0.4,2.4,0.5,3,0.4c0.5-0.2,0.9-0.5,1.1-1.1c0.2-0.4,0.1-0.9-0.2-1.4
		c-0.3-0.5-1.1-1.1-2.5-1.8c-1.3-0.7-3.4-1.6-6.2-2.6c-2.3-0.8-4.1-1.5-5.5-1.9c-1.4-0.4-2.4-0.6-3.1-0.7c-0.7-0.1-1.2,0-1.5,0.3
		C606.8,433.6,606.6,433.9,606.5,434.3z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M596.9,444.5l39.6,17.3c1,0.4,1.6,0.9,1.8,1.4c0.2,0.5,0.2,1.3-0.1,2.2c-0.3,1-0.9,2.3-1.6,4.1l-41.3-18
		c-0.4-0.2-0.6-0.4-0.7-0.6c-0.1-0.2,0.1-0.8,0.4-1.7C595.3,448.2,595.9,446.7,596.9,444.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M588.4,461.3c0.5-1.1,1.1-2,1.8-2.9c0.7-0.9,1.5-1.5,2.5-2c1-0.4,2.2-0.6,3.5-0.4c1.4,0.2,3,0.7,5,1.7
		c1.9,1,3.4,1.9,4.4,2.9c1,1,1.7,1.9,2.1,2.9c0.4,1,0.5,1.9,0.3,2.8s-0.4,1.8-0.8,2.6c-0.2,0.4-0.6,1-1,1.7c-0.5,0.7-1,1.2-1.6,1.5
		c0.9,0.4,1.6,0.8,2.2,1.1c0.6,0.3,1.2,0.6,1.7,0.9c0.5,0.2,0.9,0.4,1.3,0.4c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.7,0.9-1.3
		c0.5-1.1,1-2.3,1.4-3.6c0.4-1.4,0.7-2.6,0.8-3.7c0.8,0,1.6,0.1,2.3,0.3c0.7,0.2,1.3,0.5,1.8,0.7c0.3,0.2,0.7,0.4,1,0.8
		c0.3,0.3,0.5,0.7,0.6,1c0.1,0.6,0,1.7-0.4,3.3c-0.3,1.7-1.1,3.6-2.2,5.9c-1.3,2.6-2.7,4.2-4.3,4.7c-1.6,0.6-3.6,0.2-6.2-1.1
		l-2.6-1.3c-2.1-1.1-4.3-2.2-6.6-3.3s-4.4-2.2-6.3-3.2c-1.9-1-3.6-2-4.9-2.8c-0.1-1.2,0.1-2.6,0.4-4.2
		C587,464.6,587.6,463,588.4,461.3z M593.4,464.3c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3,0,0.6,0.1,0.9c0.4,0.2,1,0.6,1.8,1
		c0.8,0.4,1.7,0.9,2.7,1.4c1,0.5,1.9,0.9,2.6,1.3c0.3-0.1,0.5-0.3,0.7-0.5s0.4-0.5,0.5-0.7c0.2-0.3,0.2-0.6,0.2-0.9
		c0-0.3-0.1-0.6-0.4-1c-0.3-0.3-0.6-0.7-1.2-1.1c-0.5-0.4-1.3-0.8-2.2-1.3c-0.7-0.3-1.3-0.6-1.8-0.8c-0.5-0.2-0.9-0.3-1.3-0.3
		c-0.4,0-0.7,0-1,0.2C593.8,463.7,593.6,464,593.4,464.3z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M583.3,472.6l25.7,15.5c-0.1,0.7-0.2,1.7-0.6,2.8s-0.7,2.3-1.2,3.5c-0.5,1.2-1,2.2-1.5,3.1
		c-1.1,1.8-2.2,3.2-3.3,4.1c-1.1,0.9-2.3,1.3-3.6,1.2s-2.9-0.6-4.7-1.7l-19.5-11.8c-0.4-0.2-0.6-0.5-0.6-0.8c0-0.3,0.2-0.9,0.7-1.8
		c0.5-0.9,1.3-2.4,2.6-4.4l18.7,11.3c1.4,0.8,2.4,1.3,3.1,1.4s1.2-0.2,1.7-1c0.2-0.4,0.4-0.8,0.5-1.2c0.1-0.4,0.1-0.8,0.1-1.3
		l-20.6-12.4c-0.4-0.2-0.6-0.5-0.6-0.7s0.2-0.8,0.7-1.7C581.3,476,582.1,474.6,583.3,472.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M565.9,498.6c0.8-1.1,1.7-2,2.6-2.9s2.1-1.3,3.5-1.5c1.4-0.2,3,0.1,5,0.8c1.9,0.7,4.3,2,7,4
		c3.5,2.5,6.1,4.8,7.8,7c1.7,2.1,2.6,4.1,2.8,5.8c0.2,1.7-0.2,3.3-1.1,4.6c-0.5,0.7-1,1.2-1.5,1.6c-0.5,0.4-1.1,0.6-1.5,0.8l9.9,7.1
		c0.9,0.6,1.3,1.2,1.5,1.7c0.1,0.5-0.1,1.2-0.6,2.1c-0.5,0.9-1.4,2.1-2.5,3.7l-36.6-26.2c0.2-1.1,0.6-2.4,1.2-3.9
		S564.7,500.3,565.9,498.6z M570.9,502.3c-0.2,0.3-0.4,0.7-0.5,1c-0.1,0.3-0.2,0.7-0.2,1l15.9,11.4c0.2-0.1,0.4-0.2,0.6-0.4
		c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.3-0.6,0.4-0.9s-0.1-0.9-0.4-1.6c-0.4-0.7-1.1-1.5-2.2-2.5c-1.1-1-2.7-2.3-4.8-3.8
		c-1.5-1.1-2.8-2-3.9-2.6c-1.1-0.7-1.9-1.1-2.6-1.4c-0.7-0.3-1.2-0.4-1.7-0.3C571.5,501.7,571.2,501.9,570.9,502.3z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M553.3,514.8c0.8-0.9,1.6-1.7,2.4-2.4c0.8-0.7,1.8-1.1,2.9-1.4c1.1-0.2,2.2-0.1,3.5,0.4
		c1.3,0.5,2.8,1.4,4.5,2.8c1.6,1.4,2.9,2.7,3.7,3.8c0.8,1.2,1.3,2.3,1.4,3.3c0.1,1,0,1.9-0.3,2.8c-0.3,0.8-0.8,1.6-1.4,2.3
		c-0.3,0.4-0.8,0.8-1.4,1.4c-0.6,0.6-1.3,0.9-1.9,1.1c0.7,0.6,1.4,1.1,1.9,1.6s1,0.8,1.5,1.2c0.4,0.3,0.8,0.6,1.2,0.7
		c0.4,0.1,0.7,0,1.1-0.1c0.4-0.2,0.8-0.5,1.2-1c0.8-0.9,1.5-2,2.2-3.2c0.7-1.2,1.3-2.4,1.6-3.4c0.8,0.2,1.5,0.4,2.2,0.8
		c0.7,0.4,1.2,0.7,1.6,1.1c0.3,0.2,0.6,0.6,0.8,1c0.2,0.4,0.4,0.8,0.3,1.1c0,0.6-0.4,1.6-1.1,3.2c-0.7,1.5-1.9,3.3-3.5,5.2
		c-1.9,2.2-3.6,3.4-5.2,3.6c-1.6,0.2-3.6-0.6-5.7-2.5l-2.3-1.9c-1.8-1.5-3.7-3.1-5.7-4.7c-1.9-1.6-3.7-3.1-5.4-4.6
		c-1.7-1.4-3.1-2.7-4.2-3.8c0.2-1.2,0.6-2.5,1.4-3.9S552.1,516.3,553.3,514.8z M557.5,518.9c-0.2,0.2-0.4,0.5-0.5,0.8
		c-0.1,0.3-0.2,0.6-0.1,0.9c0.3,0.3,0.8,0.8,1.6,1.4c0.7,0.6,1.5,1.3,2.3,2c0.8,0.7,1.6,1.3,2.3,1.9c0.3,0,0.6-0.1,0.8-0.3
		c0.3-0.2,0.5-0.4,0.6-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0.1-0.3,0-0.6-0.2-1c-0.2-0.4-0.5-0.8-0.9-1.3c-0.4-0.5-1-1.1-1.8-1.7
		c-0.6-0.5-1.1-0.9-1.5-1.2c-0.4-0.3-0.8-0.5-1.2-0.6c-0.4-0.1-0.7-0.1-1,0C558,518.4,557.7,518.6,557.5,518.9z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M539.5,531.4l29.7,30.9c0.3,0.3,0.4,0.6,0.4,0.9c0,0.3-0.3,0.7-0.8,1.2l-4.4,4.2c-1.5,1.4-3,2.5-4.6,3.1
		c-1.6,0.6-3.2,0.7-5,0.4s-3.7-1.3-5.9-2.8c-2.2-1.5-4.6-3.6-7.2-6.4c-3.2-3.3-5.7-6.3-7.5-8.9s-2.9-5-3.4-7.1
		c-0.5-2.1-0.5-3.9,0-5.6c0.5-1.6,1.5-3.1,2.9-4.5L539.5,531.4z M539.1,538.4l-2,2c-0.6,0.6-1,1.3-1.2,2.2c-0.2,0.9-0.1,2,0.4,3.4
		c0.5,1.4,1.5,3.2,3.1,5.3c1.6,2.2,3.9,4.8,6.9,8c2,2.1,3.8,3.7,5.3,4.9c1.5,1.2,2.8,2,4,2.4c1.1,0.4,2.1,0.5,3,0.3
		c0.9-0.3,1.7-0.7,2.4-1.5l2.1-2L539.1,538.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M522,547.7l18.9,22.2c0.5,0.6,0.8,1.1,0.7,1.6c0,0.4-0.3,0.9-0.8,1.5c-0.5,0.5-1.3,1.3-2.4,2.2l-19.8-23.2
		c-0.2-0.3-0.3-0.5-0.3-0.7c0-0.2,0.3-0.5,0.8-1C519.7,549.6,520.7,548.8,522,547.7z M543.3,576.6c0.6-0.5,1.3-0.7,2-0.5
		c0.7,0.1,1.6,0.8,2.5,1.9c0.7,0.9,1.1,1.8,1.2,2.6c0.1,0.8-0.2,1.6-1,2.2c-0.7,0.6-1.4,0.9-2.1,0.7c-0.6-0.2-1.4-0.8-2.2-1.8
		c-0.8-1-1.3-1.9-1.4-2.7C542.3,578,542.6,577.2,543.3,576.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M506.4,559.4c1.1-0.9,2.3-1.5,3.6-1.9c1.2-0.4,2.5-0.5,3.9-0.2s2.8,1.1,4.4,2.3c1.5,1.2,3.2,3.1,5.1,5.5
		c2.4,3.1,4,5.9,5,8.2c0.9,2.3,1.4,4.2,1.3,5.8c-0.1,1.6-0.6,3-1.4,4.1c-0.8,1.1-1.8,2.1-2.9,3c-2.1,1.6-4.2,2.1-6.3,1.6
		c-2.1-0.5-4.2-2.2-6.2-4.9c-0.8-1.1-1.7-2.3-2.5-3.5c-0.8-1.2-1.4-2.4-1.9-3.5c1-1.1,2.1-2.2,3.4-3.4c1.3-1.2,2.6-2.3,3.8-3.3
		c1.2-1,2.7-2.1,4.3-3.4l2.5,3.4c-1.3,0.9-2.4,1.7-3.3,2.5c-0.9,0.7-1.8,1.5-2.7,2.3c-0.9,0.8-1.6,1.6-2.1,2.3
		c0.3,0.5,0.6,1.1,0.9,1.7c0.4,0.6,0.7,1.1,1.1,1.5c1.4,1.8,2.6,2.9,3.5,3.4c1,0.5,2,0.3,3.1-0.6c0.6-0.4,1-1,1.3-1.7
		c0.3-0.7,0.3-1.6,0.1-2.7c-0.2-1.1-0.7-2.4-1.5-3.9c-0.8-1.5-2-3.4-3.7-5.5c-1.2-1.6-2.3-2.8-3.2-3.7c-1-0.9-1.9-1.5-2.7-1.8
		c-0.8-0.3-1.6-0.3-2.4-0.1s-1.6,0.6-2.4,1.2c-1,0.8-1.9,1.6-2.7,2.7c-0.8,1-1.4,2.1-1.8,3.2c-0.5-0.3-0.9-0.7-1.4-1.1
		c-0.4-0.5-0.8-0.9-1.1-1.3c-0.4-0.5-0.4-1.2-0.1-2.1c0.3-0.9,0.9-1.9,1.8-2.9C504.1,561.4,505.2,560.4,506.4,559.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M496.3,567.5l16,24.5c-0.5,1.4-1.3,2.7-2.2,3.9c-1,1.2-2.1,2.3-3.4,3.1c-1.4,0.9-2.4,1.3-3.2,1.3
		c-0.8,0-1.4-0.4-1.9-1.1c-0.2-0.3-0.4-0.7-0.6-1.2c-0.2-0.5-0.4-1.1-0.5-1.6c0.5,0,1-0.1,1.5-0.3c0.5-0.2,1-0.4,1.4-0.7
		c0.6-0.4,1.1-0.9,1.6-1.5c0.5-0.6,0.9-1.2,1.1-2l-13.5-20.8c-0.2-0.3-0.2-0.5-0.2-0.7c0.1-0.2,0.4-0.5,0.9-0.9
		C493.9,569.1,494.9,568.5,496.3,567.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M474.8,580.1c1.2-0.7,2.5-1.2,3.8-1.4c1.3-0.3,2.6-0.2,3.9,0.3c1.3,0.5,2.6,1.4,4,2.9c1.4,1.4,2.8,3.5,4.3,6.2
		c1.9,3.4,3.2,6.4,3.8,8.8c0.6,2.4,0.8,4.4,0.5,6c-0.3,1.6-1,2.9-1.9,3.9c-1,1-2.1,1.9-3.3,2.6c-2.3,1.3-4.5,1.5-6.5,0.7
		c-2-0.8-3.8-2.7-5.5-5.7c-0.7-1.2-1.3-2.5-2-3.8c-0.6-1.3-1.1-2.6-1.4-3.7c1.1-1,2.4-1.9,3.9-2.9c1.5-1,2.9-1.9,4.3-2.7
		c1.4-0.8,2.9-1.8,4.7-2.8l2,3.7c-1.4,0.7-2.6,1.4-3.6,2c-1,0.6-2,1.2-3,1.9c-1,0.7-1.8,1.3-2.4,2c0.2,0.6,0.4,1.2,0.7,1.8
		c0.3,0.6,0.6,1.2,0.9,1.7c1.1,2,2.2,3.2,3,3.8c0.9,0.6,1.9,0.5,3.1-0.1c0.6-0.3,1.1-0.9,1.5-1.5c0.4-0.7,0.5-1.6,0.5-2.7
		c0-1.1-0.4-2.5-1-4.1c-0.6-1.6-1.6-3.6-2.9-6c-1-1.7-1.9-3.1-2.7-4.1c-0.8-1-1.6-1.7-2.4-2.1c-0.8-0.4-1.6-0.5-2.3-0.5
		c-0.8,0.1-1.6,0.4-2.6,0.9c-1.1,0.6-2.1,1.4-3,2.3c-0.9,0.9-1.7,1.9-2.2,2.9c-0.4-0.3-0.8-0.8-1.2-1.3c-0.4-0.5-0.7-1-0.9-1.5
		c-0.3-0.5-0.2-1.3,0.2-2.1c0.4-0.9,1.1-1.8,2.2-2.7C472.2,581.7,473.4,580.8,474.8,580.1z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M463.8,586.7l12.5,27.2c-0.6,0.7-1.4,1.4-2.3,2.2c-0.9,0.8-1.9,1.5-2.9,2.2c-1,0.7-2,1.3-3.1,1.8
		c-2,0.9-3.7,1.4-5.1,1.4c-1.4,0-2.6-0.4-3.6-1.3c-1-0.9-1.9-2.3-2.8-4.1l-9.5-20.6c-0.1-0.3-0.2-0.5-0.1-0.7
		c0.1-0.2,0.5-0.4,1.1-0.8c0.6-0.3,1.7-0.9,3.4-1.6l9.3,20.1c0.8,1.8,1.6,3,2.4,3.5c0.8,0.6,1.9,0.5,3.3-0.1
		c0.6-0.3,1.3-0.7,2.1-1.2s1.3-1.1,1.8-1.8l-10.7-23.2c-0.1-0.3-0.2-0.5-0.1-0.7c0.1-0.2,0.4-0.4,1.1-0.8
		C461.2,588,462.3,587.4,463.8,586.7z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M434.9,598.4c1.1-0.4,2.2-0.7,3.3-0.9c1.1-0.2,2.1-0.1,3.1,0.2c1,0.3,2,1,2.9,2c0.9,1,1.7,2.5,2.5,4.6
		c0.8,2.1,1.1,3.8,1.2,5.2c0,1.4-0.2,2.6-0.7,3.6c-0.5,1-1.2,1.7-2,2.3c-0.9,0.6-1.8,1.1-2.8,1.4c-0.6,0.2-1.4,0.4-2.3,0.6
		c-0.9,0.2-1.7,0.3-2.4,0.2c0.4,1.1,0.7,2,1,2.8c0.3,0.7,0.5,1.4,0.7,1.9c0.3,0.9,0.8,1.6,1.2,2c0.5,0.4,1,0.6,1.6,0.6
		c0.6,0,1.3-0.1,2.1-0.4c1.5-0.6,3-1.2,4.3-2c1.3-0.8,2.3-1.5,3.1-2.2c0.4,0.4,0.8,0.8,1.1,1.3c0.3,0.5,0.5,0.9,0.6,1.3
		c0.1,0.3,0.2,0.6,0.2,0.9c0,0.4-0.1,0.6-0.2,0.9c-0.4,0.5-1.3,1.2-2.8,2.2c-1.5,1-3.3,1.9-5.6,2.7c-2.6,0.9-4.6,1.1-6.2,0.4
		c-1.6-0.6-2.8-2.3-3.8-5l-1.1-3.1c-0.8-2.2-1.6-4.5-2.4-6.8s-1.6-4.4-2.2-6.4c-0.7-2-1.2-3.8-1.7-5.3c0.6-0.8,1.5-1.6,2.8-2.6
		C431.4,599.9,432.9,599.1,434.9,598.4z M435.9,602.6c-0.6,0.2-1.2,0.6-1.7,1c-0.5,0.4-0.8,0.8-1,1.2c0.2,0.7,0.4,1.5,0.8,2.7
		c0.4,1.1,0.8,2.3,1.2,3.5c0.4,1.2,0.8,2.4,1.2,3.4c0.5,0.1,1,0,1.5-0.1c0.6-0.1,1-0.2,1.4-0.4c0.6-0.2,1.2-0.5,1.7-0.8
		c0.5-0.3,0.8-0.8,1.1-1.3s0.3-1.3,0.3-2.2c-0.1-0.9-0.3-2-0.8-3.3c-0.4-1.1-0.8-1.9-1.2-2.5s-0.9-1-1.4-1.3c-0.5-0.2-1-0.3-1.5-0.3
		S436.5,602.4,435.9,602.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M422.2,603.4l8.2,28.1c-0.9,1.2-2,2.2-3.3,3.1c-1.3,0.9-2.7,1.6-4.1,2c-1.6,0.5-2.7,0.6-3.5,0.3
		c-0.7-0.2-1.2-0.8-1.5-1.6c-0.1-0.3-0.2-0.8-0.3-1.3c-0.1-0.6-0.1-1.1,0-1.7c0.5,0.1,1,0.2,1.5,0.1c0.5,0,1-0.1,1.6-0.3
		c0.7-0.2,1.3-0.5,2-0.9c0.6-0.4,1.2-0.9,1.6-1.6l-6.9-23.9c-0.1-0.3-0.1-0.6,0-0.7c0.1-0.2,0.5-0.4,1.2-0.6
		C419.4,604.3,420.5,603.9,422.2,603.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M398.3,609.1c1.7-0.4,3.1-0.3,4.1,0.3c1,0.6,1.8,1.5,2.4,2.8c0.6,1.3,1,2.9,1.5,4.7l4,17.9l4.2-0.9
		c0.3,1.3,0.5,2.3,0.6,2.9c0.1,0.6,0.1,1,0,1.2c-0.1,0.2-0.3,0.4-0.7,0.4l-3.1,0.7l2.1,9.3c0.1,0.6,0.1,1.1-0.1,1.3
		s-0.7,0.5-1.4,0.7c-0.7,0.2-1.7,0.5-3.1,0.8l-2.5-11.1l-6.3,1.4c-0.3-1.3-0.5-2.2-0.6-2.8c-0.1-0.6-0.1-1,0-1.3s0.3-0.4,0.6-0.4
		l5.3-1.2l-4-17.9c-0.2-0.9-0.4-1.7-0.6-2.4c-0.2-0.7-0.6-1.3-1-1.7c-0.4-0.4-1-0.5-1.7-0.3c-0.7,0.2-1.3,0.4-1.8,0.6
		c-0.5,0.3-1,0.6-1.6,1.1c-0.2-0.3-0.3-0.7-0.5-1.1c-0.2-0.4-0.3-0.9-0.4-1.4c-0.2-0.7,0-1.4,0.4-1.9c0.5-0.5,1.1-0.9,1.8-1.3
		C396.7,609.5,397.4,609.2,398.3,609.1z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M382.8,612c2.2-0.3,4.1-0.4,5.6-0.1c1.5,0.3,2.4,1,2.5,2.1c0.1,0.4,0.1,0.8,0,1.4c0,0.5-0.2,1.1-0.4,1.7
		c-1-0.4-2-0.6-3.1-0.7s-2.2,0-3.4,0.1c-1.6,0.2-2.8,0.8-3.6,1.5c-0.8,0.8-1.1,1.8-0.9,3.1c0.1,0.8,0.4,1.6,0.9,2.3
		c0.5,0.7,1.3,1.4,2.3,2.1l6.8,4.2c1.6,0.9,2.7,1.9,3.4,3c0.6,1,1.1,2.1,1.2,3.1c0.2,1.5,0.1,2.8-0.5,4s-1.5,2.2-3,3.1
		c-1.4,0.9-3.3,1.5-5.6,1.8c-2.2,0.3-3.8,0.4-5,0.1c-1.2-0.2-2-0.6-2.5-1c-0.5-0.5-0.8-0.9-0.9-1.4c-0.1-0.9,0.1-1.8,0.7-2.7
		c1,0.4,2,0.7,3.1,0.8c1,0.1,2.1,0.1,3.3-0.1c1.5-0.2,2.6-0.7,3.5-1.3c0.8-0.7,1.1-1.5,1-2.4c-0.1-0.5-0.3-1.1-0.7-1.6
		c-0.4-0.5-1.2-1.2-2.4-1.9l-6.5-3.8c-1.6-1.1-2.7-2.2-3.4-3.2c-0.7-1.1-1.2-2.4-1.4-4c-0.4-2.7,0.2-5,1.8-6.8
		C377.2,613.6,379.6,612.5,382.8,612z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M355.4,615c2-0.1,3.5,0,4.5,0.4c1,0.3,1.7,0.8,2.1,1.5c0.4,0.6,0.6,1.3,0.6,2c0,0.9,0,1.7-0.2,2.3
		c-0.2,0.6-0.4,1.1-0.5,1.6c-0.6-0.4-1.4-0.8-2.3-1.1c-0.9-0.4-1.9-0.5-2.8-0.5c-0.9,0-1.6,0.2-2.1,0.6c-0.4,0.3-0.7,0.9-0.9,1.8
		c-0.1,0.8-0.1,2.1-0.1,3.7l1.6,29.4c0.1,1,0,1.8-0.2,2.2c-0.2,0.4-0.8,0.7-1.8,0.8c-1,0.1-2.7,0.3-5.1,0.4l-1.8-33.9
		c-0.1-1.8,0-3.3,0.3-4.7c0.3-1.4,0.8-2.5,1.5-3.4c0.7-0.9,1.6-1.6,2.8-2.2C352.3,615.4,353.7,615.1,355.4,615z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M333.6,615.4c1.7,0,3.1,0.3,4.4,0.7s2.3,1.2,3.1,2.4c0.8,1.2,1.4,2.8,1.7,5c0.4,2.2,0.5,5,0.4,8.5
		c-0.1,5.7-1.1,9.8-3,12.4c-1.9,2.5-4.5,3.8-7.8,3.7c-2.3,0-4.1-0.5-5.6-1.5c-1.4-0.9-2.5-2.6-3.1-4.9s-0.9-5.6-0.9-9.9
		c0.1-6.1,1.1-10.4,2.8-12.9C327.6,616.5,330.2,615.3,333.6,615.4z M333.3,621.2c-0.4,0-0.8,0.2-1.2,0.6c-0.4,0.4-0.7,1.4-0.9,2.9
		c-0.3,1.5-0.4,3.9-0.5,7.1c-0.1,3,0,5.3,0.1,6.8c0.1,1.5,0.3,2.5,0.6,2.9c0.3,0.5,0.8,0.7,1.4,0.7c0.5,0,0.9-0.2,1.3-0.7
		c0.4-0.5,0.7-1.5,0.9-2.9c0.2-1.5,0.4-3.7,0.4-6.7c0-2.4,0-4.4-0.1-5.8c-0.1-1.5-0.2-2.5-0.4-3.2c-0.2-0.7-0.4-1.1-0.7-1.3
		C334.1,621.4,333.7,621.3,333.3,621.2z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M320.5,615.5l-3.3,43.1c-0.1,1-0.3,1.8-0.7,2.2c-0.4,0.4-1.1,0.6-2.2,0.6c-1,0-2.5-0.1-4.4-0.2l3.4-44.9
		c0-0.4,0.1-0.7,0.4-0.9c0.2-0.2,0.8-0.2,1.8-0.2C316.5,615.2,318.2,615.3,320.5,615.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M301.9,613c1.2,0.2,2.3,0.4,3.3,0.8c1,0.3,1.9,0.9,2.7,1.7c0.7,0.8,1.3,1.8,1.6,3.2c0.3,1.4,0.3,3.1,0,5.3
		c-0.3,2.1-0.7,3.8-1.3,5.1c-0.6,1.3-1.3,2.3-2,2.9c-0.8,0.7-1.6,1.1-2.5,1.2c-0.9,0.2-1.8,0.2-2.7,0.1c-0.5-0.1-1.1-0.2-1.9-0.4
		c-0.8-0.2-1.5-0.6-2-1c-0.1,1-0.2,1.8-0.3,2.4c-0.1,0.7-0.2,1.3-0.2,1.9c-0.1,0.5,0,1,0.1,1.3c0.1,0.4,0.4,0.6,0.7,0.9
		c0.4,0.2,0.9,0.4,1.5,0.5c1.2,0.2,2.5,0.2,3.9,0.1c1.4-0.1,2.7-0.2,3.8-0.5c0.3,0.8,0.4,1.5,0.5,2.3c0,0.8,0,1.4-0.1,1.9
		c0,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.5,0.7-0.8,0.9c-0.5,0.3-1.6,0.5-3.3,0.7c-1.7,0.2-3.8,0.2-6.3-0.2c-2.9-0.4-4.8-1.2-5.9-2.5
		c-1-1.3-1.4-3.3-1-6.2l0.4-2.9c0.3-2.4,0.6-4.8,1-7.3s0.7-4.8,1-7c0.3-2.2,0.7-4,1-5.6c1.1-0.5,2.5-0.8,4.1-1
		C298.3,612.7,300,612.8,301.9,613z M300.6,618.7c-0.3,0-0.6,0-1,0c-0.3,0.1-0.6,0.2-0.8,0.4c-0.1,0.5-0.2,1.1-0.4,2.1
		c-0.1,0.9-0.3,1.9-0.4,3c-0.1,1.1-0.3,2.1-0.4,2.9c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.8,0.2c0.3,0,0.6,0,0.9-0.1
		c0.3-0.1,0.6-0.3,0.8-0.7c0.2-0.3,0.5-0.8,0.6-1.5c0.2-0.6,0.4-1.4,0.5-2.4c0.1-0.7,0.2-1.4,0.2-1.9c0-0.5,0-1-0.1-1.4
		c-0.1-0.4-0.3-0.7-0.5-0.8C301.3,618.9,301,618.8,300.6,618.7z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M289.5,611.9l-6.2,29.4c-0.7,0.2-1.7,0.3-2.9,0.4c-1.2,0.1-2.4,0.1-3.7,0c-1.3-0.1-2.4-0.2-3.5-0.4
		c-2.1-0.4-3.7-1-4.9-1.8c-1.2-0.7-2-1.8-2.4-3c-0.4-1.3-0.3-2.9,0.1-5l4.7-22.3c0.1-0.4,0.3-0.7,0.5-0.8c0.3-0.1,0.9-0.1,1.9,0.1
		c1,0.2,2.7,0.5,5,1l-4.5,21.3c-0.3,1.6-0.4,2.7-0.3,3.4c0.1,0.6,0.6,1.1,1.5,1.2c0.4,0.1,0.8,0.1,1.3,0.1c0.4,0,0.8-0.1,1.2-0.3
		l5-23.5c0.1-0.4,0.3-0.7,0.5-0.8c0.2-0.1,0.8-0.1,1.8,0.1C285.7,611.1,287.3,611.5,289.5,611.9z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M259.2,604c1.3,0.4,2.5,0.9,3.6,1.5c1.1,0.6,1.9,1.6,2.6,2.8c0.6,1.2,0.9,2.9,0.9,5c0,2.1-0.5,4.7-1.5,7.9
		c-1.2,4.1-2.6,7.4-4,9.6c-1.5,2.3-3,3.8-4.6,4.6c-1.6,0.8-3.2,0.9-4.7,0.4c-0.8-0.2-1.5-0.5-2-0.9c-0.5-0.4-1-0.8-1.2-1.2
		l-3.5,11.7c-0.3,1-0.7,1.7-1.1,2c-0.5,0.3-1.2,0.3-2.2,0.1c-1-0.2-2.4-0.6-4.3-1.1l12.8-43.2c1.1-0.1,2.5-0.2,4.1-0.2
		S257.3,603.4,259.2,604z M257.4,610c-0.4-0.1-0.8-0.2-1.1-0.2c-0.4,0-0.7,0.1-1,0.2l-5.5,18.7c0.1,0.2,0.3,0.3,0.5,0.4
		c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.6,0.1,1,0c0.4-0.1,0.8-0.4,1.4-0.9c0.5-0.6,1.1-1.5,1.7-2.9c0.6-1.4,1.3-3.3,2-5.8
		c0.5-1.8,0.9-3.3,1.2-4.5c0.3-1.2,0.4-2.2,0.4-2.9c0-0.7-0.1-1.3-0.3-1.7C258.1,610.3,257.8,610.1,257.4,610z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M239.8,597.5c1.1,0.4,2.1,0.9,3,1.5c0.9,0.6,1.7,1.3,2.2,2.3c0.5,0.9,0.8,2.1,0.8,3.5c0,1.4-0.4,3.1-1.2,5.1
		c-0.8,2-1.6,3.6-2.4,4.7c-0.9,1.1-1.7,1.9-2.7,2.4c-0.9,0.5-1.8,0.7-2.7,0.6c-0.9-0.1-1.8-0.2-2.6-0.6c-0.4-0.2-1-0.5-1.8-0.9
		c-0.7-0.4-1.3-0.9-1.7-1.4c-0.3,0.9-0.6,1.7-0.9,2.3c-0.2,0.6-0.5,1.2-0.7,1.8c-0.2,0.5-0.3,1-0.2,1.3c0,0.4,0.2,0.7,0.5,1
		c0.3,0.3,0.8,0.6,1.4,0.8c1.1,0.4,2.4,0.8,3.8,1c1.4,0.3,2.7,0.4,3.8,0.4c0.1,0.8,0.1,1.6-0.1,2.3c-0.2,0.8-0.3,1.4-0.5,1.8
		c-0.1,0.4-0.3,0.7-0.6,1.1c-0.3,0.4-0.6,0.6-1,0.7c-0.6,0.1-1.7,0.1-3.4,0c-1.7-0.2-3.7-0.7-6.1-1.6c-2.7-1-4.4-2.3-5.1-3.8
		c-0.7-1.5-0.6-3.6,0.4-6.2l1.1-2.8c0.9-2.2,1.7-4.5,2.6-6.9c0.9-2.4,1.8-4.6,2.6-6.6c0.8-2,1.6-3.8,2.2-5.2c1.2-0.2,2.6-0.2,4.2,0
		C236.4,596.3,238.1,596.8,239.8,597.5z M237.3,602.7c-0.3-0.1-0.6-0.2-0.9-0.2c-0.4,0-0.6,0-0.9,0.2c-0.2,0.4-0.5,1.1-0.8,1.9
		c-0.4,0.9-0.7,1.8-1.1,2.8c-0.4,1-0.7,1.9-1.1,2.8c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.5,0.3,0.8,0.4c0.3,0.1,0.6,0.2,0.9,0.1
		c0.3,0,0.6-0.2,0.9-0.5c0.3-0.3,0.6-0.7,1-1.3c0.3-0.6,0.7-1.3,1-2.3c0.3-0.7,0.5-1.3,0.6-1.8c0.1-0.5,0.2-1,0.2-1.3
		c0-0.4-0.1-0.7-0.3-0.9C237.9,603,237.7,602.8,237.3,602.7z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M219.6,589.8L200.1,628c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.1-0.8,0-1.3-0.3l-5.4-2.8c-1.8-0.9-3.3-2-4.4-3.3
		c-1.1-1.3-1.8-2.8-2-4.6c-0.2-1.8,0-3.9,0.7-6.5c0.7-2.5,1.9-5.5,3.7-8.9c2.1-4.1,4.1-7.4,6-10c1.9-2.5,3.8-4.4,5.6-5.5
		c1.8-1.2,3.6-1.8,5.3-1.8c1.7,0,3.4,0.4,5.2,1.3L219.6,589.8z M212.9,591.8l-2.5-1.3c-0.7-0.4-1.6-0.5-2.4-0.5
		c-0.9,0.1-1.9,0.6-3.1,1.5c-1.2,0.9-2.5,2.5-4,4.7c-1.5,2.2-3.3,5.2-5.3,9.1c-1.3,2.6-2.3,4.8-2.9,6.6c-0.6,1.8-0.9,3.3-1,4.5
		c0,1.2,0.2,2.2,0.7,2.9c0.5,0.7,1.2,1.3,2.2,1.8l2.6,1.3L212.9,591.8z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M178.2,608.2c0.7,0.4,1.1,1,1.2,1.7c0.1,0.7-0.2,1.7-1,3c-0.6,1-1.3,1.6-2,2c-0.8,0.4-1.6,0.3-2.4-0.2
		c-0.8-0.5-1.3-1.1-1.3-1.7s0.3-1.6,1-2.7c0.6-1.1,1.4-1.8,2.1-2.2C176.5,607.6,177.3,607.7,178.2,608.2z M198.5,578.6l-14.9,25.1
		c-0.4,0.7-0.8,1.1-1.2,1.2c-0.4,0.1-1,0-1.6-0.3c-0.7-0.3-1.6-0.8-2.8-1.6l15.5-26.2c0.2-0.3,0.4-0.5,0.5-0.5
		c0.2,0,0.6,0.1,1.3,0.5C196,577.1,197,577.7,198.5,578.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M182.4,567.7c1.2,0.8,2.2,1.7,3,2.7c0.8,1,1.3,2.2,1.5,3.6c0.2,1.4-0.1,3-0.8,4.9c-0.7,1.9-1.9,4.1-3.6,6.6
		c-2.2,3.3-4.2,5.7-6.1,7.4c-1.9,1.6-3.6,2.7-5.1,3.1c-1.6,0.4-3,0.4-4.4,0c-1.3-0.4-2.6-1-3.7-1.8c-2.2-1.5-3.4-3.3-3.6-5.4
		c-0.2-2.1,0.7-4.6,2.6-7.5c0.8-1.1,1.6-2.3,2.5-3.5c0.9-1.2,1.8-2.1,2.7-2.9c1.3,0.6,2.8,1.3,4.3,2.2c1.5,0.9,3,1.7,4.3,2.6
		c1.4,0.8,2.9,1.8,4.6,3l-2.4,3.4c-1.3-0.9-2.4-1.7-3.4-2.3c-1-0.6-2-1.2-3-1.8c-1.1-0.6-2-1-2.8-1.3c-0.4,0.4-0.9,0.9-1.3,1.4
		c-0.4,0.5-0.8,1-1.1,1.5c-1.2,1.9-1.9,3.4-2,4.4c-0.1,1.1,0.4,2,1.5,2.7c0.6,0.4,1.3,0.6,2,0.6c0.8,0,1.6-0.2,2.6-0.8
		c1-0.5,2-1.4,3.2-2.7c1.2-1.3,2.5-3,4-5.3c1.1-1.6,1.9-3.1,2.5-4.3c0.5-1.2,0.8-2.2,0.8-3.1c0-0.9-0.2-1.6-0.7-2.3
		c-0.4-0.7-1.1-1.3-2-1.9c-1-0.7-2.2-1.3-3.4-1.7c-1.2-0.4-2.4-0.6-3.6-0.7c0.1-0.5,0.3-1.1,0.6-1.7c0.3-0.6,0.6-1.1,0.9-1.5
		c0.4-0.5,1-0.8,2-0.8c1,0,2.1,0.2,3.3,0.7C179.8,566.1,181.1,566.8,182.4,567.7z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M171.4,560.7l-18,23.1c-1.5,0-3-0.3-4.5-0.8c-1.5-0.5-2.8-1.2-4-2.2c-1.3-1-2.1-1.9-2.3-2.6
		c-0.2-0.7-0.1-1.5,0.4-2.2c0.2-0.3,0.5-0.6,0.9-1s0.9-0.7,1.4-1c0.2,0.5,0.5,0.9,0.8,1.3c0.3,0.4,0.7,0.8,1.1,1.1
		c0.6,0.4,1.2,0.8,1.9,1.1c0.7,0.3,1.5,0.4,2.2,0.4l15.3-19.6c0.2-0.3,0.4-0.4,0.6-0.4c0.2,0,0.6,0.2,1.2,0.6
		C169.1,559,170.1,559.7,171.4,560.7z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M152.5,544.4c1.1,1,1.9,2,2.6,3.1c0.7,1.1,1,2.4,1,3.8c0,1.4-0.5,3-1.4,4.7c-0.9,1.8-2.4,3.8-4.4,6.1
		c-2.6,2.9-5,5.1-7,6.5c-2.1,1.4-3.9,2.2-5.5,2.4c-1.6,0.2-3,0-4.3-0.6c-1.3-0.6-2.4-1.3-3.5-2.3c-2-1.8-2.9-3.7-2.8-5.9
		c0.1-2.1,1.3-4.5,3.6-7c0.9-1,1.9-2.1,3-3.1c1.1-1,2.1-1.9,3-2.5c1.3,0.7,2.6,1.6,4,2.7c1.4,1.1,2.7,2.1,4,3.1
		c1.2,1,2.6,2.2,4.2,3.6l-2.8,3.1c-1.2-1.1-2.2-2-3.1-2.8c-0.9-0.8-1.8-1.5-2.8-2.2c-1-0.7-1.8-1.3-2.6-1.6c-0.5,0.4-1,0.8-1.4,1.2
		c-0.5,0.5-0.9,0.9-1.3,1.4c-1.5,1.7-2.3,3.1-2.6,4.1c-0.3,1,0.1,2,1.2,2.9c0.5,0.5,1.2,0.8,1.9,0.9c0.8,0.1,1.6,0,2.7-0.4
		c1-0.4,2.2-1.2,3.6-2.3c1.3-1.1,2.9-2.7,4.7-4.7c1.3-1.5,2.3-2.8,3-3.9c0.7-1.1,1.1-2.1,1.2-3c0.1-0.9,0-1.6-0.3-2.4
		c-0.3-0.7-0.9-1.4-1.7-2.1c-0.9-0.8-2-1.5-3.1-2.1c-1.1-0.6-2.3-1-3.5-1.2c0.2-0.5,0.5-1,0.8-1.6c0.4-0.5,0.7-1,1.1-1.4
		c0.4-0.5,1.1-0.6,2.1-0.5c1,0.1,2,0.5,3.2,1.2C150.2,542.5,151.4,543.4,152.5,544.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M142.7,536.3L121,557c-0.9-0.3-1.8-0.8-2.8-1.4c-1-0.6-2-1.3-3-2c-1-0.7-1.9-1.5-2.7-2.3c-1.5-1.6-2.5-3-3-4.4
		c-0.5-1.3-0.5-2.6,0-3.9c0.5-1.2,1.5-2.6,3-4l16.4-15.7c0.2-0.2,0.5-0.3,0.7-0.3c0.2,0,0.6,0.3,1.1,0.8c0.5,0.5,1.4,1.4,2.6,2.6
		l-16,15.3c-1.4,1.4-2.3,2.5-2.6,3.4c-0.3,0.9,0.1,1.9,1.2,3.1c0.5,0.5,1.1,1,1.8,1.6c0.7,0.5,1.5,0.9,2.2,1.1l18.4-17.6
		c0.2-0.2,0.5-0.3,0.7-0.3c0.2,0,0.6,0.3,1.1,0.8C140.7,534.2,141.5,535.1,142.7,536.3z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M122.2,512.7c0.7,0.9,1.4,1.8,1.9,2.8s0.8,2,0.8,3c0,1.1-0.3,2.2-0.9,3.4s-1.8,2.5-3.5,3.9
		c-1.7,1.4-3.2,2.3-4.6,2.8c-1.3,0.5-2.6,0.7-3.6,0.5c-1.1-0.2-2-0.5-2.9-1.2c-0.8-0.6-1.6-1.3-2.2-2.2c-0.4-0.5-0.9-1.2-1.3-2
		s-0.8-1.5-1-2.2c-0.9,0.7-1.7,1.4-2.3,1.9c-0.6,0.5-1.1,0.9-1.6,1.3c-0.8,0.6-1.3,1.2-1.5,1.8c-0.2,0.6-0.2,1.2,0,1.7
		c0.2,0.6,0.5,1.2,1.1,1.9c1,1.3,2.1,2.4,3.3,3.4c1.2,1,2.2,1.7,3.1,2.2c-0.2,0.5-0.5,1-0.8,1.4c-0.4,0.4-0.7,0.7-1,1
		c-0.2,0.2-0.5,0.3-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.1c-0.6-0.2-1.6-0.9-3-1.9c-1.4-1.1-2.9-2.6-4.4-4.4c-1.7-2.1-2.5-4-2.4-5.7
		c0.1-1.7,1.3-3.4,3.5-5.2l2.6-2.1c1.9-1.5,3.7-3,5.6-4.5c1.9-1.5,3.7-2.9,5.3-4.2c1.7-1.3,3.2-2.4,4.5-3.3c0.9,0.3,2,0.9,3.3,1.8
		C119.6,509.9,120.9,511.1,122.2,512.7z M118.5,515c-0.4-0.5-0.9-1-1.5-1.3c-0.6-0.4-1-0.5-1.4-0.5c-0.6,0.4-1.3,0.9-2.2,1.6
		c-0.9,0.7-1.9,1.5-2.9,2.3c-1,0.8-2,1.6-2.9,2.3c0.1,0.5,0.3,0.9,0.6,1.4c0.3,0.5,0.6,0.9,0.8,1.2c0.4,0.5,0.9,1,1.3,1.3
		c0.5,0.3,1,0.5,1.6,0.6c0.6,0,1.3-0.1,2.1-0.4c0.8-0.3,1.8-0.9,2.8-1.8c0.9-0.7,1.5-1.4,2-2c0.4-0.6,0.7-1.2,0.8-1.7
		c0.1-0.5,0-1.1-0.2-1.5C119.3,516,118.9,515.5,118.5,515z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M113.3,502.4l-23.9,17c-1.4-0.5-2.8-1.2-4-2.1c-1.3-0.9-2.3-2-3.2-3.3c-0.9-1.3-1.4-2.4-1.4-3.2
		c0-0.8,0.3-1.4,1.1-1.9c0.3-0.2,0.7-0.4,1.2-0.7c0.5-0.3,1.1-0.5,1.6-0.6c0.1,0.5,0.2,1,0.4,1.5c0.2,0.5,0.4,0.9,0.8,1.4
		c0.4,0.6,0.9,1.1,1.5,1.6c0.6,0.5,1.3,0.8,2,1l20.3-14.3c0.3-0.2,0.5-0.3,0.7-0.2c0.2,0,0.5,0.3,1,0.9
		C111.6,500,112.3,501,113.3,502.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M100.1,481.6c0.9,1.5,1.3,2.8,1.1,4c-0.2,1.1-0.8,2.2-1.9,3.1c-1.1,1-2.4,1.9-4,2.9l-15.6,9.7L82,505
		c-1.1,0.7-2,1.2-2.5,1.5c-0.5,0.3-0.9,0.4-1.2,0.4c-0.2,0-0.5-0.2-0.6-0.5l-1.7-2.7l-8.1,5.1c-0.5,0.3-1,0.4-1.3,0.3
		c-0.3-0.1-0.7-0.5-1.1-1.1c-0.4-0.6-1-1.5-1.7-2.7l9.6-6l-3.4-5.5c1.1-0.7,1.9-1.2,2.5-1.5c0.5-0.3,0.9-0.5,1.2-0.4
		c0.2,0,0.4,0.2,0.6,0.5L77,497l15.6-9.7c0.7-0.5,1.4-0.9,2.1-1.4c0.6-0.5,1-0.9,1.3-1.4c0.2-0.5,0.1-1.1-0.3-1.7
		c-0.4-0.6-0.8-1.1-1.2-1.5c-0.4-0.4-0.9-0.8-1.5-1.1c0.2-0.2,0.5-0.5,0.8-0.8c0.3-0.3,0.7-0.6,1.2-0.9c0.6-0.4,1.3-0.5,1.9-0.2
		c0.6,0.3,1.2,0.7,1.8,1.3C99.2,480.2,99.7,480.9,100.1,481.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M92.3,468c1,2,1.7,3.7,1.9,5.2c0.2,1.5-0.2,2.5-1.1,3.1c-0.3,0.2-0.8,0.3-1.3,0.5c-0.5,0.1-1.1,0.2-1.7,0.1
		c0-1.1-0.1-2.1-0.3-3.1s-0.7-2.1-1.2-3.2c-0.7-1.4-1.6-2.4-2.6-2.9c-1-0.5-2.1-0.4-3.2,0.2c-0.7,0.4-1.4,0.9-1.9,1.6
		c-0.5,0.7-1,1.7-1.2,2.9l-1.8,7.8c-0.4,1.8-0.9,3.2-1.7,4.1c-0.8,0.9-1.6,1.7-2.5,2.2c-1.3,0.7-2.6,1-3.9,0.8
		c-1.3-0.1-2.6-0.7-3.9-1.8c-1.3-1.1-2.5-2.6-3.5-4.6c-1-1.9-1.6-3.5-1.8-4.7c-0.2-1.2-0.1-2.1,0.2-2.7c0.3-0.6,0.6-1.1,1-1.3
		c0.8-0.4,1.7-0.5,2.8-0.2c-0.1,1.1,0,2.1,0.2,3.2s0.7,2,1.2,3.1c0.7,1.3,1.5,2.3,2.4,2.8c0.9,0.6,1.8,0.6,2.6,0.1
		c0.5-0.3,0.9-0.7,1.3-1.2c0.4-0.6,0.7-1.5,1-2.8l1.4-7.4c0.5-1.8,1.1-3.3,1.9-4.3c0.8-1,1.9-1.9,3.3-2.7c2.4-1.3,4.8-1.5,7-0.6
		C88.9,463.2,90.8,465.1,92.3,468z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M80.4,443.1c0.8,1.9,1.1,3.3,1.1,4.4s-0.2,1.9-0.7,2.4c-0.5,0.6-1,1-1.7,1.2c-0.9,0.3-1.6,0.5-2.3,0.5
		c-0.7,0-1.2,0-1.7,0c0.2-0.7,0.3-1.6,0.3-2.6c0-1-0.1-1.9-0.5-2.8s-0.8-1.4-1.2-1.7c-0.5-0.3-1.1-0.4-2-0.2c-0.8,0.2-2,0.5-3.5,1.1
		l-27.3,11.1c-1,0.4-1.7,0.6-2.2,0.5c-0.5,0-0.9-0.5-1.4-1.4c-0.4-0.9-1.1-2.4-2-4.6l31.5-12.8c1.6-0.7,3.1-1.1,4.5-1.3
		c1.4-0.2,2.6-0.1,3.7,0.3c1.1,0.4,2.1,1,3,2S79.8,441.5,80.4,443.1z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M72.9,422.6c0.5,1.6,0.8,3.1,0.8,4.4c0,1.3-0.4,2.6-1.3,3.7c-0.8,1.1-2.2,2.2-4.2,3.3c-1.9,1-4.6,2.1-7.9,3.2
		c-5.4,1.8-9.7,2.2-12.7,1.2s-5-3-6.1-6.1c-0.7-2.2-0.8-4.1-0.4-5.7c0.4-1.7,1.6-3.2,3.6-4.6c2-1.4,5-2.7,9.1-4
		c5.8-1.9,10.2-2.4,13.1-1.5C69.9,417.3,71.9,419.4,72.9,422.6z M67.3,424.3c-0.1-0.4-0.4-0.7-1-0.9c-0.5-0.2-1.5-0.2-3,0.1
		c-1.5,0.2-3.8,0.9-6.9,1.9c-2.9,0.9-5,1.7-6.4,2.3c-1.4,0.6-2.2,1.1-2.6,1.6c-0.3,0.5-0.4,1-0.2,1.6c0.1,0.4,0.5,0.8,1.1,1
		c0.6,0.2,1.6,0.2,3.1-0.1c1.5-0.3,3.6-0.9,6.5-1.8c2.3-0.8,4.1-1.4,5.5-2c1.3-0.5,2.3-1,2.9-1.4c0.6-0.4,0.9-0.8,1-1.1
		C67.4,425,67.4,424.6,67.3,424.3z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M68.6,410.3l-41.8,10.9c-1,0.3-1.8,0.3-2.3,0c-0.5-0.3-0.9-0.9-1.3-1.9c-0.3-1-0.7-2.4-1.2-4.2l43.6-11.4
		c0.4-0.1,0.7-0.1,0.9,0.1c0.2,0.1,0.5,0.7,0.8,1.6C67.5,406.4,68,408,68.6,410.3z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M64.8,391.9c0.2,1.2,0.4,2.3,0.4,3.4c0,1.1-0.2,2.1-0.7,3.1c-0.5,1-1.3,1.8-2.5,2.5c-1.2,0.7-2.8,1.3-5,1.7
		c-2.1,0.4-3.9,0.6-5.3,0.5c-1.4-0.1-2.6-0.4-3.4-1c-0.9-0.5-1.5-1.2-2-2c-0.4-0.8-0.7-1.6-0.9-2.5c-0.1-0.5-0.2-1.1-0.2-2
		c0-0.8,0.1-1.6,0.3-2.2c-0.9,0.2-1.7,0.4-2.4,0.5c-0.7,0.1-1.3,0.3-1.9,0.4c-0.5,0.1-1,0.3-1.2,0.5c-0.3,0.2-0.5,0.5-0.6,1
		c-0.1,0.4-0.1,0.9,0.1,1.6c0.2,1.2,0.6,2.4,1.1,3.7c0.5,1.3,1.1,2.5,1.7,3.4c-0.6,0.5-1.3,0.9-2,1.2s-1.3,0.5-1.8,0.6
		c-0.4,0.1-0.8,0.1-1.2,0c-0.5-0.1-0.8-0.2-1.1-0.5c-0.4-0.4-1-1.3-1.8-2.9c-0.8-1.5-1.4-3.5-1.9-6c-0.6-2.8-0.4-5,0.5-6.4
		s2.7-2.4,5.5-2.9l2.9-0.6c2.3-0.5,4.8-1,7.2-1.5c2.5-0.5,4.8-0.9,7-1.3c2.2-0.4,4-0.7,5.6-0.9c0.8,0.9,1.6,2.1,2.2,3.5
		S64.5,390,64.8,391.9z M59.1,392.5c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.3-0.4-0.5-0.6-0.6c-0.5,0.1-1.2,0.2-2.1,0.3
		c-0.9,0.2-1.9,0.4-3,0.6c-1.1,0.2-2,0.4-2.9,0.6c-0.2,0.2-0.2,0.5-0.2,0.9s0,0.6,0.1,0.9c0.1,0.3,0.2,0.6,0.4,0.9
		c0.2,0.2,0.5,0.4,0.9,0.5c0.4,0.1,0.9,0.2,1.6,0.1c0.7,0,1.5-0.1,2.5-0.3c0.7-0.1,1.4-0.3,1.9-0.5c0.5-0.2,0.9-0.4,1.2-0.6
		c0.3-0.2,0.5-0.5,0.6-0.8C59.1,393.2,59.1,392.9,59.1,392.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M61.9,379.8L32,383.5c-0.4-0.6-0.8-1.5-1.3-2.6s-0.9-2.2-1.2-3.5c-0.4-1.2-0.6-2.4-0.7-3.4
		c-0.3-2.1-0.2-3.9,0.1-5.2c0.3-1.4,1-2.4,2.1-3.2c1.1-0.8,2.7-1.3,4.8-1.5l22.6-2.8c0.4-0.1,0.7,0,0.9,0.2c0.2,0.2,0.4,0.8,0.5,1.8
		c0.2,1,0.4,2.7,0.7,5.1L38.8,371c-1.6,0.2-2.7,0.5-3.3,0.8c-0.6,0.3-0.8,0.9-0.7,1.8c0.1,0.4,0.2,0.8,0.3,1.2s0.4,0.8,0.7,1.1
		l23.9-2.9c0.4-0.1,0.7,0,0.9,0.2c0.2,0.2,0.4,0.8,0.5,1.7C61.4,375.9,61.6,377.5,61.9,379.8z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M59.5,348.6c0.1,1.3,0,2.6-0.3,3.9c-0.3,1.2-0.9,2.4-1.8,3.4c-1,1-2.4,1.8-4.4,2.5c-2,0.6-4.6,1-7.9,1.2
		c-4.3,0.2-7.8,0-10.4-0.7c-2.6-0.6-4.6-1.6-5.8-2.8c-1.2-1.2-1.9-2.7-2-4.3c0-0.8,0-1.6,0.2-2.2c0.2-0.6,0.4-1.2,0.8-1.5l-12.2,0.5
		c-1,0-1.8-0.1-2.2-0.4c-0.4-0.3-0.7-1-0.8-2c-0.1-1-0.2-2.5-0.3-4.4l45-2c0.5,1,1,2.3,1.5,3.8C59.2,344.7,59.4,346.5,59.5,348.6z
		 M53.3,348.8c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.3-0.3-0.6-0.5-0.9l-19.5,0.9c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.3,0,0.5,0,0.7
		c0,0.3,0.1,0.6,0.3,1c0.2,0.3,0.6,0.7,1.3,1c0.7,0.3,1.8,0.5,3.3,0.6c1.5,0.1,3.5,0.1,6.1,0c1.9-0.1,3.5-0.2,4.7-0.3
		c1.2-0.1,2.2-0.3,2.9-0.5c0.7-0.2,1.2-0.5,1.5-0.8C53.2,349.6,53.3,349.2,53.3,348.8z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M59.4,328.1c0,1.2-0.2,2.3-0.4,3.4c-0.2,1.1-0.7,2-1.4,2.8c-0.7,0.8-1.7,1.5-3,1.9c-1.3,0.4-3.1,0.6-5.2,0.6
		c-2.1-0.1-3.9-0.3-5.2-0.8c-1.4-0.4-2.4-1-3.1-1.7c-0.7-0.7-1.2-1.5-1.5-2.4c-0.2-0.9-0.4-1.8-0.3-2.7c0-0.5,0.1-1.1,0.2-1.9
		c0.2-0.8,0.4-1.5,0.8-2.1c-1,0-1.8-0.1-2.5-0.1c-0.7,0-1.3,0-1.9-0.1c-0.5,0-1,0.1-1.3,0.2c-0.3,0.2-0.6,0.4-0.8,0.8
		c-0.2,0.4-0.3,0.9-0.3,1.6c0,1.2,0.1,2.5,0.3,3.9c0.2,1.4,0.5,2.7,0.8,3.7c-0.7,0.4-1.5,0.6-2.2,0.7c-0.8,0.1-1.4,0.1-1.9,0.1
		c-0.4,0-0.8-0.1-1.2-0.3c-0.4-0.2-0.8-0.4-1-0.7c-0.3-0.5-0.7-1.5-1.1-3.2c-0.4-1.7-0.6-3.7-0.5-6.3c0.1-2.9,0.7-4.9,1.9-6.1
		c1.2-1.2,3.2-1.7,6-1.6l3,0.1c2.4,0.1,4.8,0.1,7.4,0.2c2.5,0.1,4.9,0.2,7.1,0.3c2.2,0.1,4.1,0.2,5.6,0.4c0.6,1.1,1.1,2.4,1.4,3.9
		C59.3,324.5,59.4,326.2,59.4,328.1z M53.6,327.4c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.2-0.6-0.4-0.8c-0.5-0.1-1.2-0.1-2.1-0.2
		c-0.9,0-2-0.1-3.1-0.1c-1.1,0-2.1-0.1-3-0.1c-0.2,0.2-0.4,0.5-0.4,0.8s-0.1,0.6-0.1,0.8c0,0.3,0.1,0.6,0.2,0.9
		c0.1,0.3,0.4,0.5,0.8,0.7c0.4,0.2,0.9,0.4,1.5,0.5c0.6,0.1,1.5,0.2,2.5,0.2c0.8,0,1.4,0,1.9,0c0.5,0,1-0.1,1.3-0.3
		c0.4-0.1,0.6-0.3,0.8-0.6C53.5,328.1,53.6,327.8,53.6,327.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M62.3,289.5L20.3,281c-0.4-0.1-0.6-0.2-0.8-0.5c-0.2-0.3-0.2-0.7,0-1.4l1.2-6c0.4-2,1.1-3.7,2-5.1
		c0.9-1.4,2.2-2.4,3.9-3.1c1.7-0.7,3.8-1,6.4-1c2.6,0,5.8,0.4,9.5,1.1c4.5,0.9,8.3,2,11.2,3.1c3,1.2,5.2,2.4,6.8,3.9
		c1.6,1.4,2.6,2.9,3.2,4.6c0.5,1.6,0.6,3.4,0.2,5.3L62.3,289.5z M58.6,283.5l0.6-2.8c0.2-0.8,0.1-1.7-0.2-2.5
		c-0.3-0.8-1.1-1.7-2.3-2.5c-1.2-0.9-3.1-1.7-5.6-2.6c-2.5-0.9-5.9-1.7-10.2-2.6c-2.8-0.6-5.2-0.9-7.1-1c-1.9-0.1-3.4,0-4.6,0.3
		c-1.2,0.3-2,0.8-2.6,1.5c-0.6,0.7-1,1.6-1.2,2.6l-0.6,2.8L58.6,283.5z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M33.4,254.5c-0.2,0.8-0.7,1.3-1.4,1.6c-0.7,0.3-1.7,0.3-3.1-0.1c-1.1-0.3-1.9-0.8-2.5-1.4
		c-0.6-0.6-0.7-1.4-0.4-2.4c0.2-0.9,0.7-1.5,1.3-1.7c0.6-0.2,1.6-0.1,2.9,0.2c1.2,0.3,2.1,0.8,2.7,1.5
		C33.5,252.8,33.7,253.6,33.4,254.5z M67.4,266.2l-28.2-7.5c-0.8-0.2-1.3-0.5-1.5-0.9c-0.2-0.4-0.3-0.9-0.1-1.7s0.4-1.8,0.7-3.1
		l29.5,7.9c0.3,0.1,0.5,0.2,0.6,0.4c0.1,0.2,0.1,0.6-0.1,1.3C68.2,263.3,67.9,264.5,67.4,266.2z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M73.5,247.7c-0.4,1.4-1.1,2.6-1.8,3.6c-0.8,1.1-1.8,1.8-3.1,2.4c-1.3,0.5-2.9,0.7-4.9,0.6
		c-2-0.1-4.4-0.7-7.3-1.7c-3.7-1.2-6.7-2.5-8.7-3.9c-2.1-1.4-3.5-2.7-4.4-4.1c-0.8-1.4-1.2-2.8-1.2-4.2c0-1.4,0.3-2.8,0.7-4.1
		c0.8-2.5,2.3-4.1,4.3-4.9c2-0.7,4.6-0.6,7.9,0.5c1.3,0.4,2.7,0.9,4,1.5c1.4,0.6,2.5,1.2,3.5,1.8c-0.2,1.4-0.5,3-0.9,4.7
		c-0.4,1.7-0.9,3.3-1.3,4.9c-0.4,1.5-1,3.3-1.6,5.2l-3.9-1.4c0.5-1.5,1-2.8,1.3-3.9c0.3-1.1,0.7-2.2,0.9-3.4
		c0.3-1.2,0.4-2.2,0.5-3.1c-0.5-0.3-1.1-0.6-1.7-0.8c-0.6-0.2-1.2-0.5-1.8-0.7c-2.2-0.7-3.8-0.9-4.8-0.8c-1,0.2-1.8,0.9-2.2,2.2
		c-0.2,0.7-0.2,1.4-0.1,2.1c0.2,0.7,0.7,1.5,1.4,2.3c0.8,0.8,1.9,1.6,3.5,2.4c1.5,0.8,3.6,1.6,6.2,2.5c1.9,0.6,3.5,1,4.8,1.2
		c1.3,0.2,2.4,0.2,3.2-0.1c0.8-0.2,1.5-0.7,2-1.2c0.5-0.6,1-1.4,1.3-2.4c0.4-1.2,0.6-2.4,0.7-3.7c0.1-1.3,0-2.5-0.3-3.7
		c0.5,0,1.1,0,1.8,0.2c0.6,0.1,1.2,0.3,1.7,0.5c0.6,0.2,1,0.8,1.3,1.7c0.3,0.9,0.3,2.1,0.2,3.4C74.3,244.8,74,246.2,73.5,247.7z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M77.2,235.3l-27.1-11.1c-0.4-1.5-0.5-3-0.4-4.5c0.1-1.6,0.4-3,1-4.5c0.6-1.5,1.3-2.5,1.9-2.9
		c0.7-0.4,1.4-0.5,2.2-0.2c0.3,0.1,0.7,0.3,1.2,0.6c0.5,0.3,1,0.6,1.4,1c-0.4,0.3-0.8,0.7-1.1,1.1s-0.6,0.9-0.8,1.4
		c-0.3,0.7-0.4,1.4-0.5,2.1c-0.1,0.8,0,1.5,0.2,2.2l23,9.4c0.3,0.1,0.5,0.3,0.6,0.5c0.1,0.2,0,0.6-0.3,1.3
		C78.3,232.6,77.9,233.7,77.2,235.3z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M87.9,212.7c-0.6,1.3-1.4,2.4-2.3,3.3c-0.9,0.9-2,1.6-3.4,1.9c-1.3,0.3-3,0.3-4.9-0.1
		c-1.9-0.4-4.3-1.3-7.1-2.6c-3.5-1.7-6.3-3.4-8.1-5c-1.9-1.6-3.1-3.2-3.8-4.6c-0.6-1.5-0.8-2.9-0.6-4.3c0.2-1.4,0.6-2.7,1.2-4
		c1.2-2.4,2.8-3.8,4.9-4.3c2.1-0.5,4.7,0,7.7,1.5c1.2,0.6,2.5,1.3,3.8,2c1.3,0.7,2.4,1.5,3.2,2.3c-0.4,1.4-0.9,2.9-1.5,4.6
		c-0.7,1.6-1.3,3.2-2,4.7s-1.4,3.1-2.3,5l-3.7-1.9c0.7-1.4,1.4-2.6,1.8-3.7c0.5-1,1-2.1,1.4-3.3c0.4-1.1,0.7-2.1,0.9-3
		c-0.5-0.4-1-0.7-1.6-1.1c-0.6-0.3-1.1-0.6-1.7-0.9c-2.1-1-3.6-1.4-4.7-1.4c-1.1,0-1.9,0.6-2.5,1.9c-0.3,0.6-0.4,1.3-0.4,2.1
		c0.1,0.8,0.4,1.6,1.1,2.5c0.7,0.9,1.7,1.8,3.1,2.8c1.4,1,3.3,2.1,5.8,3.3c1.8,0.9,3.3,1.5,4.6,1.9c1.3,0.4,2.3,0.5,3.2,0.4
		c0.9-0.1,1.6-0.4,2.2-1c0.6-0.5,1.1-1.3,1.6-2.2c0.6-1.1,1-2.3,1.2-3.6c0.2-1.3,0.3-2.5,0.2-3.7c0.5,0,1.1,0.2,1.7,0.4
		c0.6,0.2,1.1,0.5,1.6,0.7c0.6,0.3,0.9,0.9,1.1,1.9c0.1,1,0,2.1-0.3,3.4C89.1,209.9,88.6,211.3,87.9,212.7z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M93.1,201l-25.8-15.3c0.1-0.9,0.3-1.9,0.6-3.1c0.3-1.1,0.7-2.3,1.1-3.4c0.4-1.2,1-2.2,1.5-3.2
		c1.1-1.9,2.3-3.2,3.4-4.1c1.2-0.8,2.4-1.2,3.7-1c1.3,0.2,2.9,0.8,4.6,1.8l19.5,11.6c0.3,0.2,0.4,0.4,0.5,0.6c0,0.2-0.1,0.6-0.5,1.3
		c-0.3,0.6-0.9,1.7-1.8,3.2L81,178.1c-1.7-1-3-1.5-4-1.6c-1,0-1.8,0.6-2.6,2c-0.4,0.6-0.7,1.3-1,2.2c-0.3,0.8-0.5,1.7-0.4,2.5
		l21.9,13c0.3,0.2,0.4,0.4,0.5,0.5c0,0.2-0.1,0.6-0.4,1.2C94.6,198.6,94,199.6,93.1,201z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M110.3,175c-0.7,1-1.4,1.8-2.2,2.6c-0.8,0.8-1.7,1.3-2.7,1.6c-1,0.3-2.2,0.3-3.5,0c-1.3-0.3-2.9-1.1-4.7-2.4
		c-1.8-1.3-3.1-2.5-3.9-3.6c-0.8-1.2-1.3-2.3-1.5-3.4c-0.1-1.1,0-2.1,0.4-3.1s0.9-1.9,1.5-2.7c0.4-0.5,0.9-1.2,1.5-1.8
		c0.6-0.7,1.2-1.2,1.8-1.5c-1-0.7-1.8-1.2-2.4-1.7c-0.6-0.4-1.2-0.8-1.7-1.2c-0.8-0.6-1.5-0.9-2.1-0.9c-0.6,0-1.2,0.1-1.7,0.4
		c-0.5,0.3-1,0.8-1.5,1.5c-0.9,1.3-1.7,2.7-2.4,4.1c-0.6,1.4-1.1,2.6-1.3,3.6c-0.6-0.1-1.1-0.2-1.6-0.4c-0.5-0.2-0.9-0.5-1.2-0.7
		c-0.2-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.6,0.4-1.8,1.1-3.4c0.7-1.6,1.7-3.4,3.1-5.4c1.6-2.2,3.2-3.5,4.8-3.9
		c1.6-0.4,3.6,0.3,6,1.9l2.7,1.9c2,1.4,3.9,2.8,5.9,4.2c2,1.4,3.8,2.8,5.5,4c1.7,1.3,3.2,2.4,4.4,3.4c-0.1,1-0.3,2.2-0.8,3.7
		C112.3,171.8,111.5,173.3,110.3,175z M107.1,172.1c0.4-0.5,0.7-1.1,0.9-1.8c0.2-0.6,0.2-1.1,0.1-1.5c-0.5-0.5-1.2-1-2.2-1.7
		c-0.9-0.7-1.9-1.4-3-2.2c-1-0.8-2-1.5-3-2.1c-0.4,0.2-0.8,0.5-1.2,1c-0.4,0.4-0.7,0.8-1,1.1c-0.4,0.5-0.7,1.1-0.9,1.6
		c-0.2,0.5-0.3,1.1-0.1,1.7c0.1,0.6,0.4,1.2,1,1.9c0.5,0.7,1.4,1.4,2.5,2.2c0.9,0.7,1.7,1.1,2.4,1.4c0.7,0.3,1.3,0.3,1.9,0.3
		c0.5-0.1,1-0.3,1.4-0.6C106.4,173.1,106.8,172.6,107.1,172.1z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M117.9,163.6l-22.7-18.5c0.1-1.5,0.4-3,0.9-4.4c0.5-1.5,1.3-2.8,2.3-4c1-1.3,1.9-2,2.7-2.2
		c0.8-0.2,1.5-0.1,2.2,0.5c0.3,0.2,0.6,0.5,1,1c0.4,0.4,0.7,0.9,1,1.4c-0.5,0.2-0.9,0.5-1.4,0.8c-0.4,0.3-0.8,0.7-1.1,1.1
		c-0.5,0.6-0.8,1.2-1.1,1.9c-0.3,0.7-0.4,1.4-0.4,2.2l19.2,15.7c0.3,0.2,0.4,0.4,0.4,0.6c0,0.2-0.2,0.6-0.6,1.2
		C119.8,161.4,119,162.3,117.9,163.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M134.4,145.4c-1.2,1.3-2.4,2-3.5,2.1c-1.2,0.1-2.3-0.2-3.5-1c-1.2-0.8-2.5-1.8-3.9-3.1L110,131.1l-2.9,3.2
		c-1-0.9-1.7-1.6-2.1-2c-0.4-0.4-0.7-0.8-0.7-1c0-0.3,0.1-0.5,0.3-0.7l2.2-2.4l-7.1-6.5c-0.5-0.4-0.7-0.8-0.7-1.2
		c0-0.4,0.3-0.8,0.7-1.4c0.5-0.6,1.2-1.4,2.1-2.4l8.4,7.7l4.3-4.7c1,0.9,1.7,1.5,2.1,2c0.4,0.4,0.7,0.8,0.7,1c0,0.2-0.1,0.5-0.3,0.7
		l-3.7,4l13.5,12.4c0.6,0.6,1.3,1.1,1.9,1.6c0.6,0.5,1.2,0.8,1.7,0.8s1.1-0.2,1.6-0.7c0.5-0.5,0.9-1,1.1-1.5
		c0.3-0.5,0.5-1.1,0.7-1.8c0.3,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.5,1.2,0.9c0.6,0.5,0.8,1.1,0.7,1.8c-0.1,0.7-0.4,1.4-0.8,2.1
		C135.5,144.1,135,144.8,134.4,145.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M145.5,134.2c-1.6,1.5-3.1,2.6-4.5,3.2c-1.4,0.6-2.5,0.5-3.2-0.3c-0.3-0.3-0.5-0.6-0.8-1.1
		c-0.3-0.5-0.5-1-0.6-1.6c1-0.2,2-0.6,2.9-1.1c0.9-0.5,1.8-1.2,2.7-2c1.2-1.1,1.8-2.2,2-3.3c0.2-1.1-0.1-2.1-1-3.1
		c-0.6-0.6-1.3-1.1-2.1-1.4c-0.8-0.3-1.9-0.5-3.1-0.4l-8,0.4c-1.8,0.1-3.3,0-4.4-0.5c-1.1-0.5-2-1.1-2.8-1.9c-1-1.1-1.6-2.3-1.9-3.6
		c-0.2-1.3,0-2.7,0.7-4.2c0.7-1.5,1.9-3.1,3.5-4.7c1.6-1.5,2.9-2.5,4-2.9c1.1-0.5,2-0.7,2.7-0.6c0.7,0.1,1.2,0.3,1.5,0.6
		c0.6,0.6,0.9,1.5,1,2.7c-1.1,0.2-2.1,0.6-3,1.1c-0.9,0.5-1.8,1.2-2.6,2c-1.1,1-1.8,2.1-2.1,3.1c-0.3,1-0.1,1.9,0.6,2.6
		c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.6,0.3,3,0.2l7.5-0.6c1.9,0,3.5,0.2,4.7,0.7c1.2,0.5,2.4,1.3,3.5,2.5c1.9,2,2.7,4.2,2.4,6.6
		C149.1,129.7,147.8,132,145.5,134.2z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M166.3,116.1c-1.6,1.2-2.9,2-3.9,2.3c-1,0.3-1.9,0.3-2.5,0c-0.7-0.3-1.2-0.7-1.7-1.3c-0.6-0.7-0.9-1.4-1.1-2
		c-0.2-0.6-0.3-1.2-0.5-1.6c0.7,0,1.6-0.2,2.5-0.4c1-0.2,1.8-0.7,2.6-1.2c0.7-0.6,1.2-1.1,1.4-1.6c0.2-0.5,0.1-1.2-0.3-1.9
		c-0.4-0.8-1.1-1.8-2-3l-18-23.3c-0.6-0.8-1-1.5-1.1-1.9c-0.1-0.5,0.2-1,1-1.7c0.7-0.7,2-1.7,3.9-3.2l20.8,26.9
		c1.1,1.4,1.9,2.7,2.5,4c0.6,1.3,0.8,2.5,0.7,3.6c-0.1,1.2-0.4,2.3-1.1,3.4S167.6,115.1,166.3,116.1z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M183.9,103.4c-1.4,0.9-2.7,1.6-4,1.9c-1.3,0.4-2.6,0.3-3.9-0.2c-1.3-0.5-2.7-1.6-4.3-3.1
		c-1.5-1.6-3.3-3.8-5.2-6.8c-3.2-4.8-4.7-8.7-4.6-11.9s1.5-5.7,4.3-7.5c1.9-1.3,3.7-1.9,5.4-2c1.7-0.1,3.5,0.7,5.4,2.3
		c1.9,1.6,4,4.1,6.3,7.6c3.4,5.1,5,9.1,5,12.2C188.2,99,186.8,101.5,183.9,103.4z M180.8,98.4c0.3-0.2,0.6-0.6,0.6-1.2
		c0.1-0.5-0.2-1.5-0.9-2.9c-0.6-1.4-1.9-3.4-3.7-6.1c-1.7-2.5-3-4.4-3.9-5.5c-0.9-1.2-1.7-1.9-2.2-2.1c-0.5-0.2-1.1-0.1-1.6,0.2
		c-0.4,0.3-0.6,0.7-0.6,1.3c0,0.6,0.3,1.6,0.9,3c0.7,1.4,1.8,3.3,3.5,5.8c1.3,2,2.5,3.6,3.4,4.8c0.9,1.1,1.6,2,2.1,2.4
		c0.5,0.5,1,0.7,1.4,0.7C180.2,98.7,180.5,98.6,180.8,98.4z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M194.7,95.8l-21.8-37.3c-0.5-0.9-0.7-1.6-0.6-2.2c0.1-0.6,0.6-1.1,1.4-1.7c0.8-0.6,2.1-1.4,3.7-2.3l22.7,38.9
		c0.2,0.4,0.3,0.7,0.2,0.9c-0.1,0.2-0.5,0.6-1.4,1.2C198.1,93.8,196.7,94.7,194.7,95.8z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M211.4,87.3c-1,0.5-2.1,1-3.1,1.3c-1,0.3-2.1,0.3-3.2,0.1c-1.1-0.2-2.1-0.8-3.1-1.7c-1-0.9-2-2.4-3-4.3
		c-1-1.9-1.6-3.6-1.9-5c-0.3-1.4-0.3-2.6,0-3.6s0.7-1.8,1.4-2.4c0.6-0.6,1.4-1.2,2.2-1.6c0.4-0.2,1-0.4,1.8-0.7
		c0.8-0.3,1.5-0.4,2.2-0.3c-0.4-0.9-0.8-1.6-1.1-2.2c-0.3-0.6-0.6-1.2-0.9-1.7c-0.2-0.5-0.5-0.8-0.8-1.1c-0.3-0.2-0.7-0.3-1.1-0.3
		c-0.4,0-0.9,0.2-1.5,0.5c-1,0.5-2.1,1.2-3.3,2.1c-1.1,0.9-2.1,1.7-2.9,2.5c-0.7-0.5-1.2-1-1.7-1.6c-0.5-0.6-0.8-1.1-1-1.6
		c-0.2-0.3-0.3-0.7-0.4-1.2c-0.1-0.5,0-0.8,0.1-1.2c0.3-0.5,1-1.3,2.3-2.5c1.3-1.1,3-2.3,5.3-3.4c2.6-1.3,4.7-1.7,6.2-1.3
		c1.6,0.5,3,2,4.3,4.5l1.3,2.6c1.1,2.1,2.2,4.3,3.4,6.6c1.1,2.2,2.2,4.4,3.2,6.3c1,2,1.7,3.7,2.3,5.1c-0.6,1-1.6,2.1-2.8,3.1
		C214.5,85.5,213.1,86.4,211.4,87.3z M209.2,81.9c0.3-0.1,0.5-0.3,0.8-0.6c0.3-0.2,0.4-0.5,0.5-0.8c-0.2-0.4-0.5-1.1-0.9-1.9
		c-0.4-0.9-0.9-1.8-1.4-2.7c-0.5-1-0.9-1.9-1.3-2.6c-0.3-0.1-0.6-0.1-0.9,0c-0.3,0.1-0.6,0.2-0.8,0.3c-0.3,0.2-0.5,0.4-0.7,0.6
		c-0.2,0.3-0.3,0.6-0.3,1c0,0.4,0.1,0.9,0.3,1.6c0.2,0.6,0.5,1.4,1,2.3c0.3,0.7,0.7,1.2,1,1.7c0.3,0.4,0.6,0.8,0.9,1
		c0.3,0.3,0.6,0.4,0.9,0.4C208.5,82.2,208.9,82.1,209.2,81.9z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M222.2,81.2l-11.6-27.7c0.5-0.6,1.2-1.2,2.1-1.9c0.9-0.7,1.9-1.4,3-2.1c1.1-0.7,2.1-1.2,3.1-1.6
		c2-0.8,3.7-1.3,5.1-1.3c1.4-0.1,2.6,0.3,3.7,1.2c1,0.8,2,2.2,2.8,4.2l8.8,21c0.2,0.4,0.2,0.7,0,0.9c-0.1,0.2-0.7,0.6-1.6,1
		c-0.9,0.4-2.5,1.1-4.7,2l-8.4-20.1c-0.6-1.5-1.2-2.5-1.6-3c-0.5-0.5-1.1-0.5-1.9-0.2c-0.4,0.2-0.8,0.4-1.1,0.7
		c-0.3,0.3-0.6,0.6-0.8,1l9.3,22.2c0.2,0.4,0.2,0.7,0,0.9c-0.1,0.2-0.6,0.6-1.5,1C225.8,79.6,224.3,80.3,222.2,81.2z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M251.6,70.5c-1.3,0.4-2.5,0.7-3.8,0.7c-1.3,0.1-2.5-0.2-3.7-0.9c-1.2-0.7-2.4-1.8-3.6-3.6
		c-1.1-1.7-2.2-4.2-3.3-7.3c-1.3-4.1-2.1-7.5-2.2-10.2c-0.1-2.7,0.3-4.8,1.2-6.4c0.9-1.5,2.1-2.5,3.6-3.1c0.8-0.3,1.5-0.4,2.2-0.4
		c0.7,0,1.2,0.1,1.7,0.3L240,28.2c-0.3-1-0.4-1.8-0.2-2.3c0.2-0.5,0.8-1,1.7-1.4c1-0.4,2.3-0.9,4.2-1.5l14,42.8
		c-0.9,0.8-1.9,1.6-3.2,2.4C255.2,69.1,253.6,69.9,251.6,70.5z M249.7,64.6c0.4-0.1,0.7-0.3,1-0.5c0.3-0.2,0.5-0.5,0.8-0.7
		l-6.1-18.6c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.6,0.3-0.8,0.5c-0.3,0.3-0.5,0.8-0.6,1.6
		c-0.1,0.8,0,1.9,0.3,3.3c0.3,1.5,0.8,3.4,1.6,5.9c0.6,1.8,1.1,3.3,1.6,4.4c0.5,1.2,0.9,2,1.3,2.7c0.4,0.6,0.8,1,1.2,1.2
		C248.9,64.7,249.3,64.7,249.7,64.6z"
					/>
					<path
						className={`svg-fill ${color ? 'svg-fill--' + color : ''}`}
						d="M271.3,64.9c-1.1,0.3-2.3,0.4-3.3,0.5c-1.1,0.1-2.1-0.1-3.1-0.6c-1-0.5-1.9-1.3-2.6-2.4
		c-0.8-1.1-1.4-2.8-2-4.9c-0.5-2.1-0.7-3.8-0.7-5.3c0.1-1.4,0.3-2.6,0.8-3.5c0.5-0.9,1.1-1.6,1.9-2.1c0.8-0.5,1.6-0.8,2.5-1
		c0.4-0.1,1.1-0.2,1.9-0.3c0.8-0.1,1.6,0,2.2,0.2c-0.2-0.9-0.4-1.7-0.6-2.4c-0.2-0.7-0.3-1.3-0.5-1.8c-0.1-0.5-0.3-0.9-0.6-1.2
		c-0.2-0.3-0.6-0.5-1-0.5c-0.4-0.1-0.9,0-1.6,0.1c-1.1,0.3-2.4,0.7-3.7,1.3c-1.3,0.6-2.4,1.2-3.4,1.8c-0.5-0.6-1-1.3-1.3-2
		s-0.5-1.3-0.6-1.8c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.5,0.2-0.8,0.4-1.1c0.4-0.4,1.3-1.1,2.8-1.9c1.5-0.8,3.5-1.5,5.9-2.1
		c2.8-0.7,4.9-0.6,6.4,0.2c1.4,0.8,2.5,2.6,3.2,5.4l0.7,2.9c0.6,2.3,1.2,4.7,1.8,7.2c0.6,2.4,1.1,4.7,1.6,6.9c0.5,2.2,0.9,4,1.1,5.5
		c-0.9,0.9-2,1.7-3.4,2.4C274.8,63.8,273.2,64.4,271.3,64.9z M270.4,59.1c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.2,0.5-0.4,0.6-0.6
		c-0.1-0.5-0.2-1.1-0.4-2.1c-0.2-0.9-0.4-1.9-0.7-3c-0.3-1.1-0.5-2-0.7-2.9c-0.3-0.2-0.5-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.8,0.1
		c-0.3,0.1-0.6,0.2-0.8,0.4c-0.2,0.2-0.4,0.5-0.5,0.9c-0.1,0.4-0.1,0.9-0.1,1.6c0.1,0.7,0.2,1.5,0.4,2.5c0.2,0.7,0.4,1.3,0.6,1.8
		c0.2,0.5,0.4,0.9,0.6,1.2c0.2,0.3,0.5,0.5,0.8,0.6C269.7,59.2,270.1,59.2,270.4,59.1z"
					/>
				</g>
			</svg>
		</>
	);
}

export default RoundLogo;
