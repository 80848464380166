import React from 'react';

function IconPlant() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 105" xmlSpace="preserve">
			<g>
				<path
					className="svg-fill svg-fill--white"
					d="M47.9,97V57.9c-5.2,0-10.3-1-15.1-3c-4.8-2-9.1-4.8-12.8-8.5c-3.7-3.7-6.5-7.9-8.5-12.8s-2.9-9.9-2.9-15.1V8.7h9.8
	c5.2,0,10.1,1,15,3s9.1,4.8,12.8,8.5c2.5,2.5,4.6,5.3,6.3,8.3s3,6.3,3.9,9.7c0.4-0.6,0.9-1.1,1.3-1.7s1-1.1,1.6-1.7
	c3.7-3.7,7.9-6.5,12.8-8.5c4.8-2,9.9-3,15.1-3H97v9.8c0,5.2-1,10.3-3,15.1c-2,4.8-4.8,9.1-8.5,12.8c-3.7,3.7-7.9,6.5-12.7,8.5
	c-4.8,2-9.8,2.9-15,2.9V97H47.9z M47.9,47.9c0-3.9-0.8-7.7-2.3-11.2c-1.5-3.6-3.7-6.7-6.4-9.5c-2.8-2.8-5.9-4.9-9.5-6.4
	c-3.6-1.5-7.3-2.3-11.2-2.3c0,3.9,0.7,7.7,2.2,11.3s3.6,6.8,6.4,9.6c2.8,2.8,6,4.9,9.6,6.4C40.3,47.2,44,47.9,47.9,47.9z M57.8,62.7
	c3.9,0,7.7-0.7,11.2-2.2c3.6-1.5,6.7-3.6,9.5-6.4c2.8-2.8,4.9-6,6.4-9.6c1.5-3.6,2.3-7.4,2.3-11.3c-3.9,0-7.7,0.8-11.3,2.3
	c-3.6,1.5-6.8,3.7-9.6,6.4c-2.8,2.8-4.9,5.9-6.4,9.5C58.5,55,57.8,58.7,57.8,62.7z"
				/>
			</g>
		</svg>
	);
}

export default IconPlant;
