import React from 'react';

function IconArrowRight() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 200 200" xmlSpace="preserve">
			<g>
				<line className="svg-stroke svg-stroke--light svg-stroke--fat" x1="14.4" y1="100" x2="185.6" y2="100" />
				<line className="svg-stroke svg-stroke--light svg-stroke--fat" x1="185.6" y1="100" x2="125.1" y2="40.8" />
				<line className="svg-stroke svg-stroke--light svg-stroke--fat" x1="185.6" y1="100" x2="125.1" y2="159.2" />
			</g>
		</svg>
	);
}

export default IconArrowRight;
