import React from 'react';

function IconMail() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 105" xmlSpace="preserve">
			<g>
				<path
					className="svg-fill svg-fill--white"
					d="M55.9,9.3c-1.9-1-4.2-1-6,0L11.3,29.8c-2.1,1.1-3.4,3.3-3.4,5.7v5.2l36.9,22.2l8-4.8l8,4.8l36.9-22.2v-5.2
	c0-2.4-1.3-4.6-3.4-5.7L55.9,9.3z M97.8,48.2L67.1,66.7l30.7,18.4V48.2z M97.6,92.5L52.8,65.6L8.1,92.4c0.8,2.8,3.3,4.7,6.2,4.7
	h77.1C94.3,97.2,96.8,95.2,97.6,92.5L97.6,92.5z M7.9,85.1l30.7-18.4L7.9,48.2V85.1L7.9,85.1z M46.8,3.6c3.8-2,8.3-2,12.1,0
	l38.6,20.6c4.2,2.2,6.8,6.6,6.8,11.3v55.3c0,7.1-5.8,12.9-12.9,12.9H14.3c-7.1,0-12.9-5.8-12.9-12.9V35.5c0-4.7,2.6-9.1,6.8-11.3
	L46.8,3.6z"
				/>
			</g>
		</svg>
	);
}

export default IconMail;
