import '../styles/_components/PhotoBlock.scss';

type ContentBlockProps = {
	className?: string;
};

function PhotoBlock({ className = '' }: ContentBlockProps) {
	return <div id="photo1" className={`photoblock ${className}`}></div>;
}

export default PhotoBlock;
