import '../styles/_components/PhotoRow.scss';

type ContentBlockProps = {
	className?: string;
	imageId:string;
};

function PhotoRowBackground({ className = '', imageId }: ContentBlockProps) {
	return <div className={`photorow photorow--bg ${className}`} id={imageId}></div>;
}

export default PhotoRowBackground;
