import React from 'react';

function IconHeart() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 105" xmlSpace="preserve">
			<g>
				<path
					className="svg-fill svg-fill--white"
					d="M17.4,61c6.7,10,18,21,35.5,32.6C70.4,82.1,81.7,71,88.3,61H74.6c-1.1,0-2.1-0.7-2.5-1.7l-7.9-19.8l-8.7,30.4
		c-0.4,1.4-1.9,2.3-3.4,1.9c-0.8-0.2-1.5-0.8-1.8-1.6l-9-22.4l-8,12C32.9,60.6,32,61,31.1,61H17.4z"
				/>
				<path
					className="svg-fill svg-fill--white"
					d="M14.1,55.6c-18-34.4,19.2-54.4,37.8-37.3c0.3,0.3,0.6,0.6,1,0.9c0.3-0.3,0.6-0.6,1-0.9c18.6-17.1,55.7,2.9,37.8,37.3H76.4
		L66.2,30.1c-0.6-1.4-2.1-2.1-3.5-1.5c-0.8,0.3-1.4,1-1.6,1.8l-8.7,30.4L44.5,41c-0.6-1.4-2.1-2.1-3.5-1.5c-0.5,0.2-0.9,0.6-1.2,1
		L29.6,55.6H14.1z"
				/>
			</g>
		</svg>
	);
}

export default IconHeart;
