import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';

interface AnimatedPageProps {
	children?: ReactNode;
	title?: string;
}

const animations = {
	initial: { opacity: 0, y: -100 },
	animate: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: 100 },
};

const animationsOverlay = {
	initial: { opacity: 1 },
	animate: { opacity: 0 },
	exit: { opacity: 1 },
};

const animationsLogoOverlay = {
	initial: { opacity: 1, y: 0 },
	animate: { opacity: 1, y: 100 },
	exit: { opacity: 1, y: 100 },
};

function AnimatedPage({ children, title }: AnimatedPageProps) {
	return (
		<>
			<motion.div variants={animations} initial="initial" animate="animate" exit="exit" transition={{ duration: 1.2 }}>
				{children}
			</motion.div>
			<motion.div variants={animationsOverlay} initial="initial" animate="animate" exit="exit" transition={{ duration: 3 }} className="pagetransition"></motion.div>
			<motion.div variants={animationsLogoOverlay} initial="initial" animate="animate" exit="exit" transition={{ duration: 1, delay: 3.5, ease: 'easeInOut' }} className="pagetransitionlogo">
				<h1>{title ? title : 'Dierenarts <strong>Jolanda</strong>'}</h1>
			</motion.div>
		</>
	);
}

export default AnimatedPage;
